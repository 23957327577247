import clsx from 'clsx';
import {
	type ChangeEvent,
	cloneElement,
	type ComponentPropsWithoutRef,
	type ElementType,
	type FormEvent,
	type MouseEvent,
	type RefObject,
	useLayoutEffect,
	useRef
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { HtmlLabelProps, SemanticShorthandItem } from '../Generic';
import {
	createHTMLLabel,
	getComponentType,
	getUnhandledProps,
	htmlInputAttrs,
	keyOnly,
	partitionHTMLProps,
	useAutoControlledValue,
	useMergedRefs
} from '../lib';

/** Props for {@link Checkbox}. */
export type CheckboxProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'input'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLInputElement>;

		/** Whether or not checkbox is checked. */
		checked?: boolean;

		/** Additional classes. */
		className?: string;

		/** The initial value of checked. */
		defaultChecked?: boolean;

		/** Whether or not checkbox is indeterminate. */
		defaultIndeterminate?: boolean;

		/** A checkbox can appear disabled and be unable to change states */
		disabled?: boolean;

		/** Removes padding for a label. Auto applied when there is no label. */
		fitted?: boolean;

		/** A unique identifier. */
		id?: string;

		/** Whether or not checkbox is indeterminate. */
		indeterminate?: boolean;

		/** The text of the associated label element. */
		label?: SemanticShorthandItem<HtmlLabelProps>;

		/** The HTML input name. */
		name?: string;

		/** Called when the user attempts to change the checked state. */
		onChange?: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;

		/** Called when the checkbox or label is clicked. */
		onClick?: (event: MouseEvent<HTMLInputElement>, data: CheckboxProps) => void;

		/** Called when the user presses down on the mouse. */
		onMouseDown?: (event: MouseEvent<HTMLInputElement>, data: CheckboxProps) => void;

		/** Called when the user releases the mouse. */
		onMouseUp?: (event: MouseEvent<HTMLInputElement>, data: CheckboxProps) => void;

		/** Format as a radio element. This means it is an exclusive option. */
		radio?: boolean;

		/** A checkbox can be read-only and unable to change states. */
		readOnly?: boolean;

		/** Format to emphasize the current selection state. */
		slider?: boolean;

		/** A checkbox can receive focus. */
		tabIndex?: number;

		/** Format to show an on or off choice. */
		toggle?: boolean;

		/** HTML input type, either checkbox or radio. */
		type?: 'checkbox' | 'radio';

		/** The HTML input value. */
		value?: number | string;
	}
>;

/**
 * A checkbox allows a user to select a value from a small set of options, often binary.
 *
 * @see Form
 * @see Radio
 */
export function Checkbox(props: CheckboxProps) {
	const {
		className,
		disabled,
		label,
		id,
		name,
		radio,
		readOnly,
		slider,
		tabIndex,
		toggle,
		type = 'checkbox',
		value,
		ref
	} = props;

	const [checked, setChecked] = useAutoControlledValue({
		state: props.checked,
		defaultState: props.defaultChecked,
		initialState: false
	});
	const [indeterminate, setIndeterminate] = useAutoControlledValue({
		state: props.indeterminate,
		defaultState: props.defaultIndeterminate,
		initialState: false
	});

	const inputRef = useMergedRefs(useRef(null), ref);
	const labelRef = useRef<HTMLLabelElement>(null);

	const isClickFromMouse = useRef<boolean | undefined>(undefined);

	// ----------------------------------------
	// Effects
	// ----------------------------------------

	useLayoutEffect(() => {
		// Note: You can't directly set the indeterminate prop on the input, so we
		// need to maintain a ref to the input and set it manually whenever the
		// component updates.
		if (inputRef.current) {
			// eslint-disable-next-line react-compiler/react-compiler
			inputRef.current.indeterminate = Boolean(indeterminate);
		}
	});

	// ----------------------------------------
	// Helpers
	// ----------------------------------------

	const canToggle = () => {
		return !disabled && !readOnly && !(radio && checked);
	};

	const computeTabIndex = () => {
		if (tabIndex != null) {
			return tabIndex;
		}

		return disabled ? -1 : 0;
	};

	// ----------------------------------------
	// Handlers
	// ----------------------------------------

	const handleChange = (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>) => {
		if (!canToggle()) {
			return;
		}

		props.onChange?.(e, {
			...props,
			checked: !checked,
			indeterminate: false
		});
		setChecked(!checked);
		setIndeterminate(false);
	};

	const handleClick = (e: MouseEvent<HTMLInputElement>) => {
		const isInputClick = inputRef.current?.contains(e.target as Node);
		const isLabelClick = labelRef.current?.contains(e.target as Node);
		const isRootClick = !isLabelClick && !isInputClick;

		const hasId = id != null;
		const isLabelClickAndForwardedToInput = isLabelClick && hasId;

		// https://github.com/Semantic-Org/Semantic-UI-React/pull/3351
		if (!isLabelClickAndForwardedToInput) {
			props.onClick?.(e, {
				...props,
				checked: !checked,
				indeterminate: Boolean(indeterminate)
			});
		}

		if (isClickFromMouse.current) {
			isClickFromMouse.current = false;

			if (isLabelClick && !hasId) {
				handleChange(e);
			}

			// Changes should be triggered for the slider variation
			if (isRootClick) {
				handleChange(e);
			}

			if (isLabelClick && hasId) {
				// To prevent two clicks from being fired from the component we have to stop the propagation
				// from the "input" click: https://github.com/Semantic-Org/Semantic-UI-React/issues/3433
				e.stopPropagation();
			}
		}
	};

	const handleMouseDown = (e: MouseEvent<HTMLInputElement>) => {
		props.onMouseDown?.(e, {
			...props,
			checked: Boolean(checked),
			indeterminate: Boolean(indeterminate)
		});

		if (!e.defaultPrevented) {
			inputRef.current!.focus();
		}

		// Heads up!
		// We need to call "preventDefault" to keep element focused.
		e.preventDefault();
	};

	const handleMouseUp = (e: MouseEvent<HTMLInputElement>) => {
		isClickFromMouse.current = true;
		props.onMouseUp?.(e, {
			...props,
			checked: Boolean(checked),
			indeterminate: Boolean(indeterminate)
		});
	};

	// ----------------------------------------
	// Render
	// ----------------------------------------

	const classes = clsx(
		'ui',
		keyOnly(checked, 'checked'),
		keyOnly(disabled, 'disabled'),
		keyOnly(indeterminate, 'indeterminate'),
		// auto apply fitted class to compact white space when there is no label
		// https://semantic-ui.com/modules/checkbox.html#fitted
		keyOnly(label == null, 'fitted'),
		keyOnly(radio, 'radio'),
		keyOnly(readOnly, 'read-only'),
		keyOnly(slider, 'slider'),
		keyOnly(toggle, 'toggle'),
		'checkbox',
		className
	);
	const unhandled = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);
	const [htmlInputProps, rest] = partitionHTMLProps(unhandled, { htmlProps: htmlInputAttrs });

	// Heads Up!
	// Do not remove empty labels, they are required by SUI CSS
	const labelElement = createHTMLLabel(label, {
		defaultProps: { htmlFor: id },
		autoGenerateKey: false
	}) || <label htmlFor={id} />;

	return (
		<ElementType
			{...rest}
			className={classes}
			onClick={handleClick}
			onChange={handleChange}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
		>
			<input
				{...htmlInputProps}
				checked={checked}
				className="hidden"
				disabled={disabled}
				id={id}
				name={name}
				readOnly
				ref={inputRef}
				tabIndex={computeTabIndex()}
				type={type}
				value={value}
			/>
			{cloneElement(labelElement, { ref: labelRef })}
		</ElementType>
	);
}
const handledProps = [
	'as',
	'checked',
	'className',
	'defaultChecked',
	'defaultIndeterminate',
	'disabled',
	'fitted',
	'id',
	'indeterminate',
	'label',
	'name',
	'onChange',
	'onClick',
	'onMouseDown',
	'onMouseUp',
	'radio',
	'readOnly',
	'slider',
	'tabIndex',
	'toggle',
	'type',
	'value'
];
