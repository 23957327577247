import clsx from 'clsx';
import { type ElementType, type RefObject } from 'react';
import { getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link PlaceholderLine}. */
export type PlaceholderLineProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Additional classes. */
	className?: string;

	/** A line can specify how long its contents should appear. */
	length?: 'full' | 'very long' | 'long' | 'medium' | 'short' | 'very short';
};

/** A placeholder can contain have lines of text. */
export function PlaceholderLine(props: PlaceholderLineProps) {
	const { className, length } = props;

	const classes = clsx('line', length, className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return <ElementType {...rest} className={classes} />;
}
const handledProps = ['as', 'className', 'length'];
