import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Defines all sub-views of the activity perspective */
export const EActivityPerspectiveView = {
	COMMITS: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Commits',
		anchor: 'commits',
		requiresProject: true,
		visibleInSidebar: true,
		hasCustomAnalysisWarning: true,
		view: () => import('./commits/CommitStreamView'),
		timeTravel: {
			contentName: 'Commits',
			until: true
		}
	} as ViewDescriptor,
	COMMIT_DETAILS: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Commit Details',
		anchor: 'details',
		requiresProject: true,
		projectIsFixed: true,
		viewCreator: () => import('./commit_details/CommitDetailView')
	} as ViewDescriptor,
	RESOURCE_HISTORY: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Resource History',
		anchor: 'history',
		requiresProject: true,
		projectIsFixed: true,
		viewCreator: () => import('./resource_history/ResourceHistoryView'),
		timeTravel: {
			contentName: 'Commits',
			until: true
		}
	} as ViewDescriptor,
	METHOD_HISTORY: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Method History',
		anchor: 'method-history',
		requiresProject: true,
		projectIsFixed: true,
		viewCreator: () => import('./method_history/MethodHistoryView')
	} as ViewDescriptor,
	MERGE_REQUESTS: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Merge Requests',
		anchor: 'merge-requests',
		requiresProject: true,
		visibleInSidebar: true,
		hasCustomAnalysisWarning: true,
		view: () => import('./merge_requests/MergeRequestsDelegateView')
	} as ViewDescriptor,
	ISSUES: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Issues',
		anchor: 'issues',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./issues/overview/IssueOverviewView')
	} as ViewDescriptor,
	ISSUE_DETAIL: {
		perspective: ETeamscalePerspective.ACTIVITY,
		name: 'Issues',
		anchor: 'issues',
		action: 'view',
		requiresProject: true,
		view: () => import('./issues/details/IssueDetailView')
	} as ViewDescriptor
} as const;
