import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';

/**
 * Returns a URL to a page where a new dashboard may be created.
 *
 * @param project The project to pre-fill for the dashboard.
 * @param params
 * @param params.commit The commit parameter of the dashboard. If null, the default branch at HEAD is selected.
 */
export function newDashboard(project: string | undefined, params: { commit?: UnresolvedCommitDescriptor }): string {
	return linkTo(EDashboardPerspectiveView.DASHBOARD_NEW, project, {
		t: params.commit
	});
}

/**
 * Returns a URL to a dashboard.
 *
 * @param project Can be specified in order to keep the list of projects as it is before clicking the link.
 * @param params
 * @param params.commit The commit parameter of the dashboard. If null, the default branch at HEAD is selected.
 * @param params.kioskMode If given and true, the dashboard will be shown in kiosk mode.
 * @param params.name The name of the dashboard. May be omitted to show the default dashboard.
 */
export function dashboard(
	project: string | null,
	params: {
		commit?: UnresolvedCommitDescriptor | null;
		kioskMode?: boolean;
		name?: string;
	} = {}
) {
	return linkTo(EDashboardPerspectiveView.DASHBOARD_SHOW, project, {
		id: params.name,
		kioskViewMode: params.kioskMode,
		t: params.commit
	});
}

/**
 * Returns a URL to the sharing view of a dashboard.
 *
 * @param params
 * @param params.id The ID of the dashboard.
 */
export function dashboardSharing(params: { id: string }) {
	return linkTo(EDashboardPerspectiveView.SHARING, params);
}

/** Returns a URL to the dashboard template edit view */
export function dashboardTemplates() {
	return linkTo(EDashboardPerspectiveView.TEMPLATES);
}

/**
 * Returns a URL to the edit page of a dashboard.
 *
 * @param params
 * @param params.name The name of the dashboard. May be omitted to show the default dashboard.
 */
export function editDashboard(projectId: string, params: { name?: string; commit: UnresolvedCommitDescriptor }) {
	return linkTo(EDashboardPerspectiveView.DASHBOARD_EDIT, projectId, {
		id: params.name,
		t: params.commit
	});
}
