import type * as PopperJS from '@popperjs/core';
import type { ModifierArguments } from '@popperjs/core/lib/types';
import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import {
	type ComponentPropsWithoutRef,
	type CSSProperties,
	type ElementType,
	type JSX,
	type ReactNode,
	type RefObject,
	type SyntheticEvent,
	useEffect,
	useLayoutEffect,
	useRef,
	useState
} from 'react';
import { type Modifier, Popper, type PopperChildrenProps } from 'react-popper';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandItem } from '../Generic';
import {
	childrenUtils,
	createHTMLDivision,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useMergedRefs,
	usePrevious
} from '../lib';
import { handledPortalProps, Portal, type PortalProps } from '../lib/Portal/Portal';
import { createVirtualElement } from './lib/createVirtualElement';
import { placementMapping, positionsMapping } from './lib/positions';
import { createPopupContent, type PopupContentProps } from './PopupContent';
import { createPopupHeader, type PopupHeaderProps } from './PopupHeader';

type PopperOffsetsFunctionParams = {
	popper: PopperJS.Rect;
	reference: PopperJS.Rect;
	placement: PopperJS.Placement;
};
type PopperOffsetsFunction = (params: PopperOffsetsFunctionParams) => [number?, number?];

/** Props for {@link Popup}. */
export type PopupProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;

		/** Display the popup without the pointing arrow */
		basic?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Simple text content for the popover. */
		content?: SemanticShorthandItem<PopupContentProps>;

		/** Existing element the pop-up should be bound to. */
		context?: HTMLElement | RefObject<HTMLElement | null>;

		/** A disabled popup only renders its trigger. */
		disabled?: boolean;

		/** Enables the Popper.js event listeners. */
		eventsEnabled?: boolean;

		/** A flowing Popup has no maximum width and continues to flow to fit its content. */
		flowing?: boolean;

		/** Header displayed above the content in bold. */
		header?: SemanticShorthandItem<PopupHeaderProps>;

		/** Hide the Popup when scrolling the window. */
		hideOnScroll?: boolean;

		/** Whether the popup should not close on hover. */
		hoverable?: boolean;

		/** Invert the colors of the popup */
		inverted?: boolean;

		/**
		 * Offset values in px unit to apply to rendered popup. The basic offset accepts an array with two numbers in
		 * the form [skidding, distance]:
		 *
		 * - `skidding` displaces the Popup along the reference element
		 * - `distance` displaces the Popup away from, or toward, the reference element in the direction of its placement.
		 *   A positive number displaces it further away, while a negative number lets it overlap the reference.
		 *
		 * @see https://popper.js.org/docs/v2/modifiers/offset/
		 */
		offset?: [number, number?] | PopperOffsetsFunction;

		/** Events triggering the popup. */
		on?: 'hover' | 'click' | 'focus' | Array<'hover' | 'click' | 'focus'>;

		/** Called when a close event happens. */
		onClose?: (event: SyntheticEvent<HTMLElement> | Event, data: PopupProps) => void;

		/**
		 * Called when the portal is mounted on the DOM.
		 *
		 * @param {null}
		 * @param {object} data - All props.
		 */
		onMount?: (nothing: null, data: PopupProps) => void;

		/** Called when an open event happens. */
		onOpen?: (event: SyntheticEvent<HTMLElement> | Event, data: PopupProps) => void;

		/**
		 * Called when the portal is unmounted from the DOM.
		 *
		 * @param {null}
		 * @param {object} data - All props.
		 */
		onUnmount?: (nothing: null, data: PopupProps) => void;

		/** Disables automatic repositioning of the component, it will always be placed according to the position value. */
		pinned?: boolean;

		/** Position for the popover. */
		position?:
			| 'top left'
			| 'top right'
			| 'bottom right'
			| 'bottom left'
			| 'right center'
			| 'left center'
			| 'top center'
			| 'bottom center';

		/** Tells `Popper.js` to use the `position: fixed` strategy to position the popover. */
		positionFixed?: boolean;

		/** A wrapping element for an actual content that will be used for positioning. */
		popper?: JSX.Element;

		/** An array containing custom settings for the Popper.js modifiers. */
		popperModifiers?: Array<Modifier<unknown, object>>;

		/** A popup can have dependencies which update will schedule a position update. */
		popperDependencies?: unknown[];

		/** Popup size. */
		size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';

		/** Custom Popup style. */
		style?: CSSProperties;

		/** Element to be rendered in-place where the popup is defined. */
		trigger?: ReactNode;

		/** Popup width. */
		wide?: boolean | 'very';
	} & Omit<PortalProps, 'children'>
>;

/** Calculates props specific for Portal component. */
function getPortalProps(props: PopupProps) {
	const portalProps: Partial<PortalProps> = {};

	const on = props.on ?? ['click', 'hover'];
	const normalizedOn = Array.isArray(on) ? on : [on];

	if (props.hoverable) {
		portalProps.closeOnPortalMouseLeave = true;
		portalProps.mouseLeaveDelay = 300;
	}

	if (_.includes(normalizedOn, 'hover')) {
		portalProps.openOnTriggerClick = false;
		portalProps.closeOnTriggerClick = false;
		portalProps.openOnTriggerMouseEnter = true;
		portalProps.closeOnTriggerMouseLeave = true;
		// Taken from SUI: https://git.io/vPmCm
		portalProps.mouseLeaveDelay = 70;
		portalProps.mouseEnterDelay = 50;
	}

	if (_.includes(normalizedOn, 'click')) {
		portalProps.openOnTriggerClick = true;
		portalProps.closeOnTriggerClick = true;
		portalProps.closeOnDocumentClick = true;
	}

	if (_.includes(normalizedOn, 'focus')) {
		portalProps.openOnTriggerFocus = true;
		portalProps.closeOnTriggerBlur = true;
	}

	return portalProps;
}

/** Splits props for Portal & Popup. */
function partitionPortalProps(unhandledProps: object, closed: boolean, disabled: boolean) {
	if (closed || disabled) {
		return {};
	}

	const contentRestProps = Object.entries(unhandledProps).reduce(
		(acc, [key, val]) => {
			if (!handledPortalProps.includes(key)) {
				acc[key] = val;
			}

			return acc;
		},
		{} as Record<string, unknown>
	);
	const portalRestProps = _.pick(unhandledProps, handledPortalProps);

	return { contentRestProps, portalRestProps };
}

/** Performs updates when "popperDependencies" are not shallow equal. */
function usePositioningEffect(
	popperDependencies: unknown[] | undefined,
	positionUpdate: RefObject<(() => unknown) | null>
) {
	const previousDependencies = usePrevious(popperDependencies);

	const dependenciesAreEqual = _.isEqualWith(previousDependencies, popperDependencies);
	useLayoutEffect(() => {
		if (positionUpdate.current) {
			positionUpdate.current();
		}
	}, [positionUpdate, dependenciesAreEqual]);
}

/**
 * A Popup displays additional information on top of a page.
 *
 * ```tsx
 * import { Popup } from 'ts/components/Popup';
 * ```
 */
export function Popup(props: PopupProps) {
	const {
		basic,
		className,
		content,
		context,
		children,
		disabled = false,
		eventsEnabled = true,
		flowing,
		header,
		hideOnScroll,
		inverted,
		offset,
		pinned = false,
		popper,
		popperDependencies,
		popperModifiers = [],
		position = 'top left',
		positionFixed,
		size,
		style,
		trigger,
		wide,
		ref
	} = props;

	const [closed, setClosed] = useState(false);

	const unhandledProps = getUnhandledProps(handledProps, props);
	const { contentRestProps, portalRestProps } = partitionPortalProps(unhandledProps, closed, disabled);

	const elementRef = useMergedRefs(ref);
	const positionUpdate = useRef<null | (() => Promise<Partial<PopperJS.State> | null>)>(null);
	const timeoutId = useRef<number>(undefined);
	const triggerRef = useRef<HTMLElement>(undefined);
	const zIndexWasSynced = useRef(false);

	// ----------------------------------------
	// Effects
	// ----------------------------------------

	usePositioningEffect(popperDependencies, positionUpdate);

	useEffect(() => {
		return () => {
			clearTimeout(timeoutId.current);
		};
	}, []);

	// ----------------------------------------
	// Handlers
	// ----------------------------------------

	const handleClose = (e: SyntheticEvent<HTMLElement> | Event) => {
		props.onClose?.(e, { ...props, open: false });
	};

	const handleOpen = (e: SyntheticEvent<HTMLElement> | Event) => {
		props.onOpen?.(e, { ...props, open: true });
	};

	const hideOnScrollHandler = (e: Event) => {
		// Do not hide the popup when scroll comes from inside the popup
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/4305
		if (e.target instanceof HTMLElement && elementRef.current?.contains(e.target as Node)) {
			return;
		}

		setClosed(true);

		timeoutId.current = setTimeout(() => {
			setClosed(false);
		}, 50);

		handleClose(e);
	};

	const handlePortalMount = (e: null) => {
		props.onMount?.(e, props);
	};

	const handlePortalUnmount = (e: null) => {
		positionUpdate.current = null;
		props.onUnmount?.(e, props);
	};

	// ----------------------------------------
	// Render
	// ----------------------------------------

	const renderBody = ({
		placement: popperPlacement,
		ref: popperRef,
		update,
		style: popperStyle
	}: PopperChildrenProps) => {
		positionUpdate.current = update;

		const classes = clsx(
			'ui',
			placementMapping[popperPlacement],
			size,
			keyOrValueAndKey(wide, 'wide'),
			keyOnly(basic, 'basic'),
			keyOnly(flowing, 'flowing'),
			keyOnly(inverted, 'inverted'),
			'popup transition visible',
			className
		);
		const ElementType = getComponentType(props);

		const styles = {
			// Heads up! We need default styles to get working correctly `flowing`
			left: 'auto',
			right: 'auto',
			// This is required to be properly positioned inside wrapping `div`
			position: 'initial',
			...style
		};

		const innerElement = (
			<ElementType {...contentRestProps} className={classes} style={styles} ref={elementRef}>
				{childrenUtils.isNil(children) ? (
					<>
						{createPopupHeader(header, { autoGenerateKey: false })}
						{createPopupContent(content, { autoGenerateKey: false })}
					</>
				) : (
					children
				)}
				{hideOnScroll ? <ScrollEventListener onScroll={hideOnScrollHandler} /> : null}
			</ElementType>
		);

		// https://github.com/popperjs/popper-core/blob/f1f9d1ab75b6b0e962f90a5b2a50f6cfd307d794/src/createPopper.js#L136-L137
		// Heads up!
		// A wrapping `div` there is a pure magic, it's required as Popper warns on margins that are
		// defined by SUI CSS. It also means that this `div` will be positioned instead of `content`.
		return createHTMLDivision(popper || {}, {
			overrideProps: {
				children: innerElement,
				ref: popperRef,
				style: {
					// Fixes layout for floated elements
					// https://github.com/Semantic-Org/Semantic-UI-React/issues/4092
					display: 'flex',
					...popperStyle
				}
			}
		});
	};

	if (closed || disabled) {
		return trigger;
	}

	const modifiers = [
		{ name: 'arrow', enabled: false },
		{ name: 'eventListeners', options: { scroll: Boolean(eventsEnabled), resize: Boolean(eventsEnabled) } },
		{ name: 'flip', enabled: !pinned },
		{ name: 'preventOverflow', enabled: Boolean(offset) },
		{ name: 'offset', enabled: Boolean(offset), options: { offset } },
		...popperModifiers,

		// We are syncing zIndex from `.ui.popup.content` to avoid layering issues as in SUIR we are using an additional
		// `div` for Popper.js
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/4083
		{
			name: 'syncZIndex',
			enabled: true,
			phase: 'beforeRead',
			fn: ({ state }: ModifierArguments<Record<string, unknown>>) => {
				if (zIndexWasSynced.current) {
					return;
				}

				// if zIndex defined in <Popup popper={{ style: {} }} /> there is no sense to override it
				// @ts-ignore
				const definedZIndex = popper?.style?.zIndex;

				if (_.isUndefined(definedZIndex)) {
					state.elements.popper.style.zIndex = window.getComputedStyle(
						state.elements.popper.firstChild as Element
					).zIndex;
				}

				zIndexWasSynced.current = true;
			},
			effect: () => {
				return () => {
					zIndexWasSynced.current = false;
				};
			}
		}
	] as const;

	const referenceElement = createVirtualElement(
		// eslint-disable-next-line react-compiler/react-compiler
		(context == null ? triggerRef : context) as RefObject<HTMLElement | null> | HTMLElement
	);
	const mergedPortalProps = { ...getPortalProps(props), ...portalRestProps };

	return (
		<Portal
			{...mergedPortalProps}
			onClose={handleClose}
			onMount={handlePortalMount}
			onOpen={handleOpen}
			onUnmount={handlePortalUnmount}
			trigger={trigger}
			triggerRef={triggerRef}
		>
			<Popper
				modifiers={modifiers}
				placement={positionsMapping[position]}
				strategy={positionFixed ? 'fixed' : undefined}
				referenceElement={referenceElement}
			>
				{renderBody}
			</Popper>
		</Portal>
	);
}

function ScrollEventListener({ onScroll }: { onScroll: EventListener }) {
	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [onScroll]);
	return null;
}

const handledProps = [
	'as',
	'basic',
	'children',
	'className',
	'content',
	'context',
	'disabled',
	'eventsEnabled',
	'flowing',
	'header',
	'hideOnScroll',
	'hoverable',
	'inverted',
	'offset',
	'on',
	'onClose',
	'onMount',
	'onOpen',
	'onUnmount',
	'pinned',
	'popper',
	'popperDependencies',
	'popperModifiers',
	'position',
	'positionFixed',
	'size',
	'style',
	'trigger',
	'wide'
];
