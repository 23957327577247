import { createContext, use } from 'react';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';

type TeamscaleViewContextValue = {
	projectIds: string[];
	defaultBranchName: string | null;
	viewDescriptor: ViewDescriptor;
};

/** Exposes the view's context. */
export function useTeamscaleViewContext(): TeamscaleViewContextValue {
	const context = use(TeamscaleViewContext);
	if (context === undefined) {
		throw new Error('useTeamscaleViewContext must be used within a TeamscaleViewContext');
	}
	return context;
}

/** The view context. */
export const TeamscaleViewContext = createContext<TeamscaleViewContextValue | undefined>(undefined);
