import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EArchitecturePerspectiveView } from 'ts/perspectives/architecture/EArchitecturePerspectiveView';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';

/** Returns a URL for the Architecture overview. */
export function architectureOverview(projectId: string) {
	return linkTo(EArchitecturePerspectiveView.ARCHITECTURE_OVERVIEW, projectId);
}

/** Returns a URL for the Architecture import view. */
export function importArchitecture(projectId: string, commit?: UnresolvedCommitDescriptor | null) {
	return linkTo(EArchitecturePerspectiveView.ARCHITECTURE_IMPORT, projectId, {
		t: commit
	});
}

/**
 * Returns a URL for the Architecture editor.
 *
 * @param architectureName The name of the architecture. If not specified creates a new architecture.
 */
export function architectureEditor(
	projectId: string,
	architectureName?: string,
	params?: {
		commit?: UnresolvedCommitDescriptor | CommitDescriptor | null;
		component?: string;
	}
) {
	return linkTo(EArchitecturePerspectiveView.ARCHITECTURE_EDITOR, projectId, architectureName, {
		t: UnresolvedCommitDescriptor.wrap(params?.commit),
		component: params?.component
	});
}
