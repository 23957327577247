import type { FetchQueryOptions } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import type { ServiceCallError } from 'api/ServiceCallError';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { GitTag } from 'typedefs/GitTag';
import { DateUtils } from './../DateUtils';
import type { DefinedPointInTime } from './DefinedPointInTime';
import { EPointInTimeType } from './EPointInTimeType';
import type { Revision } from './Revision';
import type { TimeSpan } from './TimeSpan';
import type { Timestamp } from './Timestamp';
import { TimeUtils } from './TimeUtils';
import type { TypedPointInTime } from './TypedPointInTime';

/** Time context for resolving commits and timestamps relative to the current time or an optional time travel commit. */
export class TimeContext {
	/** Map for resolvers of timestamps for different kinds of points in time. */
	public resolverMap = {
		[EPointInTimeType.BASELINE]: (baseline: DefinedPointInTime): Promise<number | null> =>
			this.getTimestampForBaseline(baseline),
		[EPointInTimeType.REVISION]: (revisionPointInTime: Revision): Promise<number | null> =>
			this.getTimestampForRevision(revisionPointInTime),
		[EPointInTimeType.SYSTEM_VERSION]: (systemVersion: DefinedPointInTime): Promise<number | null> =>
			this.getTimestampForSystemVersion(systemVersion),
		[EPointInTimeType.TIMESTAMP]: (dateTime: Timestamp): Promise<number | null> =>
			this.getTimestampForDateTime(dateTime),
		[EPointInTimeType.TIMESPAN]: (timeSpan: TimeSpan, baselineTimestamp: number): Promise<number | null> =>
			this.getTimestampForTimeSpan(timeSpan, baselineTimestamp),
		[EPointInTimeType.GIT_TAG]: (gitTagBody: GitTag): Promise<number | null> =>
			this.getTimestampForGitTags(gitTagBody)
	} as const;

	public constructor(public timeTravelCommit: UnresolvedCommitDescriptor | null = null) {
		// Nothing to do here
	}

	/** Resolves a commit relative within the given time context. */
	public resolveCommit(pointInTime: TypedPointInTime): Promise<UnresolvedCommitDescriptor>;
	public resolveCommit(pointInTime: TypedPointInTime | null): Promise<UnresolvedCommitDescriptor | null>;
	public resolveCommit(pointInTime: TypedPointInTime | null): Promise<UnresolvedCommitDescriptor | null> {
		return this.resolveCommitRelative(pointInTime, this.getTimeTravelCommit());
	}

	/** Ensures that a end commit is not in the future of the timetravel commit. */
	public ensureEndCommitNotAfterTimetravel(commit: UnresolvedCommitDescriptor): UnresolvedCommitDescriptor {
		if (this.isBeforeTimeTravel(commit)) {
			return commit;
		}
		return UnresolvedCommitDescriptor.createCommitFromTimestamp(
			this.getTimeTravelCommit()?.timestamp ?? null,
			commit
		);
	}

	/** Resolves the commit for a point in time relative to the time of the given baseline commit. */
	public resolveCommitRelative(
		pointInTime: TypedPointInTime | null,
		baselineCommit: UnresolvedCommitDescriptor | null
	): Promise<UnresolvedCommitDescriptor | null> {
		if (pointInTime === null) {
			return Promise.resolve(null);
		}
		return this.resolveTimestampAgainstBaseLine(pointInTime, baselineCommit).then(timestamp => {
			if (timestamp === null) {
				return baselineCommit ?? new UnresolvedCommitDescriptor();
			}
			if (pointInTime.type === EPointInTimeType.REVISION) {
				return new UnresolvedCommitDescriptor(timestamp, pointInTime.value.branch);
			}
			if (pointInTime.type === EPointInTimeType.GIT_TAG) {
				return QUERY.resolveTag(pointInTime.value.projectId, pointInTime.value)
					.fetch()
					.then(commitDescriptor => UnresolvedCommitDescriptor.wrap(commitDescriptor));
			}
			return UnresolvedCommitDescriptor.createCommitFromTimestamp(timestamp, baselineCommit);
		});
	}

	/** Resolves the timestamp for a point in time relative the current time context. */
	public resolveToTimestamp(pointInTime: TypedPointInTime | null): Promise<number | null> {
		if (pointInTime === null) {
			return Promise.resolve(null);
		}
		return this.resolveTimestampAgainstBaseLine(pointInTime, this.getTimeTravelCommit());
	}

	/** Returns the configured time travel commit. */
	public getTimeTravelCommit(): UnresolvedCommitDescriptor | null {
		return this.timeTravelCommit;
	}

	/**
	 * Returns true if a commit occurred before the current time travel time.
	 *
	 * @param commit The commit to check.
	 */
	public isBeforeTimeTravel(commit: UnresolvedCommitDescriptor | null): boolean {
		if (this.getTimeTravelCommit() === null || (commit !== null && commit.getTimestamp() === null)) {
			return true;
		}
		return UnresolvedCommitDescriptor.firstCommitEarlierThanSecond(commit, this.getTimeTravelCommit());
	}

	/** Resolves the timestamp for a point in time relative the given baseline commit time. */
	private resolveTimestampAgainstBaseLine(
		pointInTime: TypedPointInTime,
		baselineCommit: UnresolvedCommitDescriptor | null
	): Promise<number | null> {
		let baselineTimestamp = null;
		if (baselineCommit !== null) {
			baselineTimestamp = baselineCommit.getTimestamp();
		}
		if (!TimeUtils.isTrend(pointInTime)) {
			return Promise.resolve(baselineTimestamp);
		}
		// @ts-ignore
		return this.resolverMap[pointInTime.type.toUpperCase()]!(pointInTime.value, baselineTimestamp);
	}

	/** Timestamp resolver function for date times. */
	public getTimestampForDateTime(dateTime: Timestamp): Promise<number | null> {
		return Promise.resolve(dateTime.timestamp);
	}

	/** Timestamp resolver function for timespans. */
	public getTimestampForTimeSpan(timespan: TimeSpan, baselineTimestamp?: number | null): Promise<number | null> {
		return Promise.resolve(DateUtils.getTimestampForDays(timespan.days, baselineTimestamp));
	}

	/** Timestamp resolver function for revisions. */
	public getTimestampForRevision(revisionPointInTime: Revision): Promise<number | null> {
		return Promise.resolve(revisionPointInTime.timestamp);
	}

	/** Timestamp resolver function for baselines. */
	public getTimestampForBaseline(baseline: DefinedPointInTime): Promise<number> {
		return QUERY.getBaseline(baseline.project, baseline.name)
			.fetch()
			.then(baselineInfo => baselineInfo.timestamp);
	}

	/** Timestamp resolver function for system versions. */
	public getTimestampForSystemVersion(systemVersion: DefinedPointInTime): Promise<number> {
		return QUERY.getDotNetVersionInfo(systemVersion.project, systemVersion.name)
			.fetch()
			.then(versionInfo => versionInfo.commit.timestamp)
			.catch(() => {
				throw TimeContext.createPromiseReject('System version', systemVersion);
			});
	}

	/** Timestamp resolver function for git Tags. */
	public getTimestampForGitTags(gitTagPointInTime: GitTag): Promise<number | null> {
		return QUERY.resolveTag(gitTagPointInTime.projectId, gitTagPointInTime)
			.fetch()
			.then(resolvedCommit => resolvedCommit?.timestamp || null);
	}

	/** Ensures that a null commit is replaced with a HEAD commit on the default branch. */
	public static ensureEndTimestamp(commit: UnresolvedCommitDescriptor | null): UnresolvedCommitDescriptor {
		if (commit == null) {
			return new UnresolvedCommitDescriptor(null, null);
		}
		return commit;
	}

	/** Ensures that a null commit is replaced with a start commit containing a timestamp of 1. */
	public static ensureStartTimestamp(commit: UnresolvedCommitDescriptor | null): UnresolvedCommitDescriptor {
		if (commit == null) {
			return UnresolvedCommitDescriptor.createCommitFromTimestamp(1, commit);
		}
		return commit;
	}

	/** Creates a promise reject for a defined point in time. */
	public static createPromiseReject(
		readablePointInTimeType: string,
		definedPointInTime: DefinedPointInTime
	): Promise<unknown> {
		return Promise.reject(
			readablePointInTimeType +
				' "' +
				definedPointInTime.name +
				'" not found for project "' +
				definedPointInTime.project +
				'".'
		);
	}
}

/** Returns a query object that would resolve the given TypedPointInTime to a commit. */
export function getResolveCommitQuery(
	pointInTime: TypedPointInTime | null
): FetchQueryOptions<UnresolvedCommitDescriptor | null, ServiceCallError> {
	return {
		queryKey: ['resolved-commit', pointInTime],
		queryFn: () => new TimeContext().resolveCommit(pointInTime)
	};
}
