import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import type { BrowserEvent } from 'ts-closure-library/lib/events/browserevent';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { ButtonSet, DefaultButtons, Dialog, EventType } from 'ts-closure-library/lib/ui/dialog';
import type { Callback } from 'ts/base/Callback';
import * as soy from 'ts/base/soy/SoyRenderer';
import { tsdom } from 'ts/commons/tsdom';
import type { WidgetEditingContext } from 'ts/perspectives/dashboard/widgets/WidgetEditingContext';
import type { WidgetDescriptor } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import type { WidgetBase } from './../WidgetBase';
import { WidgetParameterBase } from './WidgetParameterBase';

/** Utility code for dealing with widget parameters. */
export class WidgetParameterUtils {
	/**
	 * Static method for opening a dialog for editing parameters.
	 *
	 * @param title The title for the dialog. The available parameters.
	 * @param descriptor The descriptor used for reading the current values and storing new values into.
	 * @param callback Callback executed if the user clicks ok.
	 * @param widget The widget to create.
	 */
	public static openParameterDialog(
		title: string,
		parameters: WidgetParameterBase[],
		descriptor: WidgetDescriptor,
		callback: Callback<boolean>,
		widget: WidgetBase,
		commit: UnresolvedCommitDescriptor | null,
		context: WidgetEditingContext
	): void {
		const dialog = WidgetParameterUtils.prepareDialog(
			title,
			parameters,
			descriptor,
			callback,
			widget,
			commit,
			context
		);
		dialog.setDisposeOnHide(true);
		dialog.setVisible(true);
		widget.runPostRenderActions();
		events.listen(dialog, EventType.SELECT, e =>
			WidgetParameterUtils.disposeListener(parameters, widget, descriptor, dialog, callback, e)
		);
	}

	/**
	 * Should be used as callback when the user closes the dialog for editing the widget's parameters. If the dialog got
	 * not closed through the OK button but e.g. by pressing ESC or the Cancel button, the onDialogDispose methods of
	 * the parameters get called and the dialog will close. If the validation failed the dialog stays open. Otherwise
	 * the values will be stored in the descriptor, the onDialogDispose methods of the parameters get called and the
	 * dialog is disposed.
	 *
	 *     The available parameters.
	 *
	 * @param widget The widget to which the given parameters belong.
	 * @param descriptor The descriptor used for reading the current values and storing new values into.
	 * @param dialog The parameters dialog that is being disposed.
	 * @param callback Callback executed if the user clicks OK.
	 * @param e Event that triggered the listener.
	 */
	private static disposeListener(
		parameters: WidgetParameterBase[],
		widget: WidgetBase,
		descriptor: Record<string, unknown>,
		dialog: Dialog,
		callback: Callback<boolean>,
		e: BrowserEvent
	): void {
		if (e.key !== DefaultButtons.OK.key) {
			WidgetParameterUtils.callOnDialogDispose(parameters);
			return;
		}
		if (!WidgetParameterUtils.validateParameters(parameters, widget)) {
			// In this case (error occurred) the dialog should not close but stay
			// open. Calling preventDefault will cancel the event and canceling the event
			// will prevent the dialog from closing.
			e.preventDefault();
			return;
		}
		for (const parameter of parameters) {
			descriptor[parameter.getName()] = parameter.extractValue();
		}
		const shouldRerender = WidgetParameterUtils.callOnDialogDispose(parameters);
		dialog.dispose();
		callback(shouldRerender);
	}

	/**
	 * Prepares the dialog for editing the widget.
	 *
	 * @param title The title for the dialog. The available parameters.
	 * @param descriptor The descriptor used for reading the current values and storing new values into.
	 * @param callback Callback executed if the user clicks ok.
	 * @param widget The widget to create.
	 * @returns The dialog
	 */
	private static prepareDialog(
		title: string,
		parameters: WidgetParameterBase[],
		descriptor: WidgetDescriptor,
		callback: Callback<boolean>,
		widget: WidgetBase,
		commit: UnresolvedCommitDescriptor | null,
		context: WidgetEditingContext
	): Dialog {
		const dialog = new Dialog();
		dialog.setTitle(title);
		dialog.setButtonSet(ButtonSet.createOkCancel());
		const parameterNames = [];
		const parameterDescriptions = [];
		for (const parameter of parameters) {
			parameter.onDialogPreparation();
			parameterNames.push(parameter.getName());
			parameterDescriptions.push(parameter.getDescription());
		}
		const dialogContent = soy.renderAsElement(WidgetParameterTemplate.parameterTable, {
			parameterNames,
			parameterDescriptions
		});
		const parameterContainers = tsdom.getElementsByClass('parameter-container-marker', dialogContent);
		parameters.forEach((parameter, index) => {
			WidgetParameterUtils.setupWidgetParameter(
				parameter,
				parameterContainers[index]!,
				widget,
				descriptor,
				commit,
				context
			);
		});
		dialog.getContentElement()!.appendChild(dialogContent);
		return dialog;
	}

	/**
	 * Prepares a widget parameter for a given widget.
	 *
	 * @param parameter The parameter to prepare.
	 * @param parameterContainer The container element for the parameter input elements.
	 * @param descriptor The descriptor used for reading the current values and storing new values into.
	 */
	public static setupWidgetParameter(
		parameter: WidgetParameterBase,
		parameterContainer: Element,
		widget: WidgetBase,
		descriptor: WidgetDescriptor,
		commit: UnresolvedCommitDescriptor | null,
		context: WidgetEditingContext
	): void {
		parameter.setContainer(parameterContainer);
		let value = descriptor[parameter.getName()];
		if (value == null) {
			value = widget.provideParameterDefault(parameter, context);
		}
		parameter.renderInput(value, commit);
		events.listen(parameter.getEventTarget(), WidgetParameterBase.PARAMETER_CHANGED_EVENT, event =>
			widget.onParameterChanged(event.target as WidgetParameterBase)
		);
		parameter.attachFormattingHelpLink();
	}

	/**
	 * Let the widget validate the given parameters and display any invalid parameters in the dialog. Returns whether
	 * there are invalid parameters.
	 *
	 *     The parameters to check.
	 *
	 * @param widget Widget the parameters belong to.
	 * @returns True, if all checked parameters are valid.
	 */
	private static validateParameters(parameters: WidgetParameterBase[], widget: WidgetBase): boolean {
		WidgetParameterUtils.clearErrors(parameters);
		const parameterMap: Record<string, WidgetParameterBase> = {};
		for (const parameter of parameters) {
			parameterMap[parameter.getName()] = parameter;
		}
		widget.validate(parameterMap);
		let isValid = true;
		for (let j = 0; j < parameters.length; ++j) {
			if (parameters[j]!.hasError()) {
				isValid = false;
				parameters[j]!.displayErrorMessage(j);
			}
		}
		return isValid;
	}

	/**
	 * Clears all errors of the given parameters. This includes the error messages of each parameter and its
	 * highlighting.
	 *
	 * Parameters for which errors should be cleared.
	 */
	public static clearErrors(parameters: WidgetParameterBase[]): void {
		for (const parameter of parameters) {
			parameter.clearError();
			parameter.removeErrorMessage();
		}
	}

	/**
	 * Calls the onDialogDispose() method of the given parameters.
	 *
	 *     Parameters for which the onDialogDispose method should be called (in general all parameters of a widget)
	 */
	private static callOnDialogDispose(parameters: WidgetParameterBase[]): boolean {
		let shouldRerender = true;
		parameters.forEach(parameter => {
			events.removeAll(parameter.getEventTarget(), WidgetParameterBase.PARAMETER_CHANGED_EVENT);
			shouldRerender = shouldRerender && parameter.onDialogDispose();
		});
		return shouldRerender;
	}

	/**
	 * Shows or hides the entire entry (i.e., including the parameter description) for the given parameter.
	 *
	 * @param parameter The parameter
	 * @param show Whether to show the parameter
	 */
	public static setShowParameterEntry(parameter: WidgetParameterBase, show: boolean): void {
		if (parameter.getContainer() != null) {
			tsdom.setElementShown(parameter.getContainer()!.parentElement!, show);
		}
	}
}
