import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { createPath, parsePath, type Path } from 'react-router-dom';
import { urlDecode } from 'ts-closure-library/lib/string/string';
import { Links } from 'ts/commons/links/Links';
import { StringUtils } from 'ts/commons/StringUtils';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Logic for adjusting (legacy) URLs. */
export default class Redirects {
	private static readonly PREFIX_REDIRECTS: Array<[RegExp, string]> = [
		[
			/\/issues\.html#?\/([^/]+)\/\?metric=([^?]*)/,
			`/${ETeamscalePerspective.ACTIVITY.simpleName}.html#issues/$1/?action=list&metric=$2`
		],
		[
			/\/issues\.html#?\/([^/]+)\/([^?]*)\??/,
			`/${ETeamscalePerspective.ACTIVITY.simpleName}.html#issues/$1/?action=view&id=$2&`
		],
		[/\/issues\.html/, `/${ETeamscalePerspective.ACTIVITY.simpleName}.html#issues`],
		[/\/tasks\.html#?/, `/${ETeamscalePerspective.QUALITY_CONTROL.simpleName}.html#tasks`],
		[/\/reports\.html/, `/${ETeamscalePerspective.QUALITY_CONTROL.simpleName}.html#reports`],
		[/\/findings\.html#baselines/, `/${ETeamscalePerspective.QUALITY_CONTROL.simpleName}.html#baselines`],
		[/\/tests\.html/, `/${ETeamscalePerspective.TEST_GAPS.simpleName}.html`],
		[/\/metrics\.html#test-executions/, `/${ETeamscalePerspective.METRICS.simpleName}.html#tests`],
		[/\/dashboard\.html#kiosk(.*)/, `/${ETeamscalePerspective.DASHBOARD.simpleName}.html#$1&kioskViewMode=true`]
	];

	/**
	 * Creates a redirect url based on the given window location. Returns <code>null</code> if no redirect is necessary.
	 * Non-null results of this method should be applied using {@link document#location#replace} Example:
	 *
	 * - Input with <code>window.location.href === 'http://foo.com:8080/teamscale/issues.html'</code>
	 * - Result: <code>'http://foo.com:8080/teamscale/activity/issues?foo=bar'</code>
	 */
	public static createRedirectOrNull(locationHref: string): string | null {
		for (const patternAndReplacement of Redirects.PREFIX_REDIRECTS) {
			if (locationHref.match(patternAndReplacement[0])) {
				return Redirects.rewriteHtmlToBrowserUrls(
					locationHref.replace(patternAndReplacement[0], patternAndReplacement[1])
				);
			}
		}
		const rewriteCompareLink = Redirects.rewriteCompareLink(locationHref);
		if (rewriteCompareLink !== null) {
			return rewriteCompareLink;
		}
		const browserUrl = Redirects.rewriteHtmlToBrowserUrls(locationHref);
		if (browserUrl != null) {
			return browserUrl;
		}
		return Redirects.rewriteLoginTarget(locationHref);
	}

	/**
	 * Rewrites URLs to the new format (see TS-33333). For example `/dashboard.html#//?id=foo` becomes
	 * `/dashboard/show?id=foo`.
	 */
	private static rewriteHtmlToBrowserUrls(urlPathAndHash: string): string | null {
		if (urlPathAndHash.match(/\/([a-z-_]*)\.html/)) {
			const { pathname = '', hash = '' } = parsePath(urlPathAndHash);
			const newPath = Redirects.relativeLocationToArtificialPath({ pathname, hash });

			/** Adds the default view name if it is not given in the url i.e. /dashboard become /dashboard/show. */
			function addDefaultSubview(perspective: string, view: string) {
				newPath.pathname = newPath.pathname.replaceAll(
					new RegExp(`(^|https?://[^/]+)/${perspective}(//|/?$)`, 'g'),
					`$1/${perspective}/${view}/`
				);
			}

			addDefaultSubview('dashboard', 'show');
			addDefaultSubview('activity', 'commits');
			addDefaultSubview('findings', 'list');
			addDefaultSubview('metrics', 'code');
			addDefaultSubview('requirements-tracing', 'overview');
			addDefaultSubview('compare', 'code');
			addDefaultSubview('testgaps', 'code');
			addDefaultSubview('qualitycontrol', 'reports');
			addDefaultSubview('architecture', 'architecture');
			addDefaultSubview('delta', 'input');
			addDefaultSubview('project', 'project');
			addDefaultSubview('system', 'execution');
			newPath.pathname = StringUtils.stripSuffix(newPath.pathname, '/');
			newPath.pathname = StringUtils.stripSuffix(newPath.pathname, '/');
			const newUrl = createPath(newPath);
			return Redirects.rewriteLoginTarget(newUrl) ?? newUrl;
		}

		return null;
	}

	/** Converts a path from the old html based format to the new URL layout. */
	private static relativeLocationToArtificialPath(browserPath: Partial<Path>): Path {
		let { pathname = '', hash } = browserPath;
		pathname = StringUtils.stripSuffix(pathname, '/');
		pathname = StringUtils.stripSuffix(pathname, '.html');
		pathname += '/';
		if (!hash) {
			return {
				pathname,
				search: '',
				hash: ''
			};
		}
		const hashPath = parsePath(hash.substring(1));
		const hashPathname = hashPath.pathname;
		if (hashPathname) {
			pathname += hashPathname;
		}
		return {
			pathname,
			search: hashPath.search ?? '',
			hash: hashPath.hash ?? ''
		};
	}

	/**
	 * Rewrites the compare link to the new format from Teamscale versions v8.6.17 (new format was introduced in
	 * TS-33428).
	 */
	private static rewriteCompareLink(urlPathAndHash: string) {
		if (
			!(
				urlPathAndHash.includes('/compare.html#/') &&
				urlPathAndHash.includes('#@#') &&
				urlPathAndHash.includes('#&#')
			)
		) {
			return null;
		}
		const [urlPrefix, hash] = urlPathAndHash.split('/compare.html#/');
		const parts = urlDecode(hash!).split(/#&#/, 4);

		// Full path might contain line information for scrolling
		const leftFullPath = parts[0]!;
		const rightFullPath = parts[1]!;

		const isInconsistentClone = parts.length > 3 && parts[3] === 'isInconsistentClone';
		let leftStartLine, leftEndLine, rightStartLine, rightEndLine;
		if (parts.length > 2) {
			[, leftStartLine, leftEndLine, rightStartLine, rightEndLine] = parts[2]!.match(/(\d+)-(\d+):(\d+)-(\d+)/)!;
		}

		//The first capturing group (.+?) matches the project name or alias. The second capturing group (.+?) after the
		// slash matches the file's uniform path. It is then followed by the last optional capturing group matching the
		// '#@#' followed by the timestamp information.
		const matcher = /^(.+?)\/(.+?)(#@#.+)?$/;
		const leftProjectAndPathMatch = leftFullPath.match(matcher)!;
		const leftProject = leftProjectAndPathMatch[1]!;
		const leftUniformPath = leftProjectAndPathMatch[2]!;
		const leftAdditionInfo = Redirects.parseAdditionalPathInfo(leftProjectAndPathMatch[3]);
		const rightProjectAndPathMatch = rightFullPath.match(matcher)!;
		const rightProject = rightProjectAndPathMatch[1]!;
		const rightUniformPath = rightProjectAndPathMatch[2]!;
		const rightAdditionInfo = Redirects.parseAdditionalPathInfo(rightProjectAndPathMatch[3]);
		return (
			urlPrefix +
			'/' +
			Links.comparePerspective(
				{
					project: leftProject,
					uniformPath: leftUniformPath,
					commit: leftAdditionInfo.commit,
					startLine: leftStartLine ? Number(leftStartLine) : leftAdditionInfo.initialLine,
					endLine: Number(leftEndLine)
				},
				{
					project: rightProject,
					uniformPath: rightUniformPath,
					commit: rightAdditionInfo.commit,
					startLine: rightStartLine ? Number(rightStartLine) : rightAdditionInfo.initialLine,
					endLine: Number(rightEndLine)
				},
				{ isInconsistentClone }
			)
		);
	}

	/**
	 * If possible, parses the branch, timestamp and initial line number from the given commit string. If the string is
	 * null, undefined or doesn't match any of the possible commit info patterns, nothing happens.
	 */
	private static parseAdditionalPathInfo(commitInfo: string | undefined): {
		commit?: UnresolvedCommitDescriptor;
		initialLine?: number;
	} {
		if (commitInfo == null) {
			return {};
		}
		const separator = '(#@#)';
		const timestampPattern = '(?<timestamp>(\\d{9,13}|HEAD)(p\\d+)?)';
		const branchAndTimestampPattern = '(?<branch>[^:]*)(?<colon>:?)' + timestampPattern;
		const lineNumberPattern = '(:)(\\d+)';

		// Check for the three cases:
		// (1) (opt_branch :) timestamp : line number
		const branchWithTimestampAndLine = commitInfo.match(
			new RegExp(separator + branchAndTimestampPattern + lineNumberPattern + '$')
		);
		if (branchWithTimestampAndLine != null) {
			return {
				commit: this.getCommit(branchWithTimestampAndLine),
				initialLine: parseInt(branchWithTimestampAndLine[8]!, 10)
			};
		}

		// (2) (opt_branch :) timestamp
		const branchWithTimestamp = commitInfo.match(new RegExp(separator + branchAndTimestampPattern + '$'));
		if (branchWithTimestamp != null) {
			return {
				commit: this.getCommit(branchWithTimestamp)
			};
		}

		// (3) (opt_branch :) line number
		const branchWithLine = commitInfo.match(new RegExp(separator + '((?<branch>[^:]+):)?(\\d+)$'));
		if (branchWithLine != null) {
			return {
				commit: this.getCommit(branchWithLine),
				initialLine: parseInt(branchWithLine[4]!, 10)
			};
		}

		return {};
	}

	/** Extracts the commit from the matched regex array. */
	private static getCommit(match: RegExpMatchArray) {
		const branchAndTimestamp =
			(match.groups!.branch ?? '') + (match.groups!.colon ?? '') + (match.groups!.timestamp ?? '');
		return UnresolvedCommitDescriptor.fromString(branchAndTimestamp) ?? undefined;
	}

	/** Rewrites the "target" search parameter according to #rewriteHtmlToBrowserUrls (see TS-38894). */
	private static rewriteLoginTarget(urlPathAndHash: string): string | null {
		const { pathname = '', search = '', hash = '' } = parsePath(urlPathAndHash);
		const urlSearchParams = new URLSearchParams(search);
		const target = urlSearchParams.get('target');
		if (pathname.includes('/login') && target != null) {
			const newTarget = Redirects.rewriteHtmlToBrowserUrls('/' + target);
			if (newTarget != null) {
				urlSearchParams.set('target', StringUtils.stripPrefix(newTarget, '/'));
				return createPath({ pathname, search: urlSearchParams.toString(), hash });
			}
		}
		return null;
	}
}
