import { cloneElement, isValidElement, type JSX, type RefObject } from 'react';
import { useMergedRefs } from '../../lib';

/** Assigns merged ref to an existing element is possible or wraps it with an additional "div". */
export default function usePortalElement(node: JSX.Element, userRef: RefObject<HTMLElement | null> | undefined) {
	const ref = useMergedRefs(node.props.ref, userRef);

	if (isValidElement(node)) {
		if (isForwardRef(node)) {
			// @ts-ignore
			return cloneElement(node, { ref });
		}

		if (typeof node.type === 'string') {
			// @ts-ignore
			return cloneElement(node, { ref });
		}
	}

	return (
		<div data-suir-portal="true" ref={ref}>
			{node}
		</div>
	);
}

function isForwardRef(object: JSX.Element): boolean {
	return object.type === Symbol.for('react.forward_ref');
}
