import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { ESystemPerspectiveView } from 'ts/perspectives/system/ESystemPerspectiveView';

/** Returns a URL for showing the Execution Status view. */
export function systemExecutionStatus() {
	return linkTo(ESystemPerspectiveView.EXECUTION_STATUS);
}

/** Returns a URL to the project parse log view. */
export function parseLog(project: string) {
	return linkTo(ESystemPerspectiveView.PARSE_LOG, {
		project
	});
}

/** Returns a URL to the system license info view. */
export function showSystemLicenseInfo() {
	return linkTo(ESystemPerspectiveView.LICENSE_INFO);
}

/** Returns a URL for showing a log view. */
export function showLogView(
	logView: ViewDescriptor,
	{
		projectId,
		level,
		searchDetailedLogs,
		filterRegex
	}: { projectId: string; level?: string; searchDetailedLogs?: string; filterRegex?: string }
) {
	return linkTo(logView, { project: projectId, level, searchDetailedLogs, filterRegex });
}
