import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ReactNode, type RefObject } from 'react';
import type { SemanticCOLORS, SemanticFLOATS, SemanticShorthandContent, SemanticTEXTALIGNMENTS } from '../Generic';
import {
	childrenUtils,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	textAlignProp,
	valueAndKey
} from '../lib';

export type SegmentSizeProp = 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive';

/** Props for {@link Segment}. */
export type SegmentProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Attach segment to other content, like a header. */
	attached?: boolean | 'top' | 'bottom';

	/** A basic segment has no special formatting. */
	basic?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** A segment can be circular. */
	circular?: boolean;

	/** Additional classes. */
	className?: string;

	/** A segment can clear floated content. */
	clearing?: boolean;

	/** Segment can be colored. */
	color?: SemanticCOLORS;

	/** A segment may take up only as much space as is necessary. */
	compact?: boolean;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A segment may show its content is disabled. */
	disabled?: boolean;

	/** Segment content can be floated to the left or right. */
	floated?: SemanticFLOATS;

	/** A segment can have its colors inverted for contrast. */
	inverted?: boolean;

	/** A segment may show its content is being loaded. */
	loading?: boolean;

	/** A segment can increase its padding. */
	padded?: boolean | 'very';

	/** A segment can be used to reserve space for conditionally displayed content. */
	placeholder?: boolean;

	/** Formatted to look like a pile of pages. */
	piled?: boolean;

	/** A segment may be formatted to raise above the page. */
	raised?: boolean;

	/** A segment can be formatted to appear less noticeable. */
	secondary?: boolean;

	/** A segment can have different sizes. */
	size?: SegmentSizeProp;

	/** Formatted to show it contains multiple pages. */
	stacked?: boolean;

	/** A segment can be formatted to appear even less noticeable. */
	tertiary?: boolean;

	/** Formats content to be aligned as part of a vertical group. */
	textAlign?: SemanticTEXTALIGNMENTS;

	/** Formats content to be aligned vertically. */
	vertical?: boolean;
};

/** A segment is used to create a grouping of related content. */
export function Segment(props: SegmentProps) {
	const {
		attached,
		basic,
		children,
		circular,
		className,
		clearing,
		color,
		compact,
		content,
		disabled,
		floated,
		inverted,
		loading,
		placeholder,
		padded,
		piled,
		raised,
		secondary,
		size,
		stacked,
		tertiary,
		textAlign,
		vertical
	} = props;

	const classes = clsx(
		'ui',
		color,
		size,
		keyOnly(basic, 'basic'),
		keyOnly(circular, 'circular'),
		keyOnly(clearing, 'clearing'),
		keyOnly(compact, 'compact'),
		keyOnly(disabled, 'disabled'),
		keyOnly(inverted, 'inverted'),
		keyOnly(loading, 'loading'),
		keyOnly(placeholder, 'placeholder'),
		keyOnly(piled, 'piled'),
		keyOnly(raised, 'raised'),
		keyOnly(secondary, 'secondary'),
		keyOnly(stacked, 'stacked'),
		keyOnly(tertiary, 'tertiary'),
		keyOnly(vertical, 'vertical'),
		keyOrValueAndKey(attached, 'attached'),
		keyOrValueAndKey(padded, 'padded'),
		textAlignProp(textAlign),
		valueAndKey(floated, 'floated'),
		'segment',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}
const handledProps = [
	'as',
	'attached',
	'basic',
	'children',
	'circular',
	'className',
	'clearing',
	'color',
	'compact',
	'content',
	'disabled',
	'floated',
	'inverted',
	'loading',
	'padded',
	'piled',
	'placeholder',
	'raised',
	'secondary',
	'size',
	'stacked',
	'tertiary',
	'textAlign',
	'vertical'
];
