/** Generated from EAnalysisState.java. Do not modify this file! */


export type EAnalysisStateEntry = 'INITIAL_ANALYSIS' | 'HISTORY_ANALYSIS' | 'LIVE_ANALYSIS' | 'CATCHUP_LIVE_ANALYSIS' | 'ROLLBACK_ANALYSIS';

export class EAnalysisState {
	static readonly INITIAL_ANALYSIS = new EAnalysisState(0, 'INITIAL_ANALYSIS');
	static readonly HISTORY_ANALYSIS = new EAnalysisState(1, 'HISTORY_ANALYSIS');
	static readonly LIVE_ANALYSIS = new EAnalysisState(2, 'LIVE_ANALYSIS');
	static readonly CATCHUP_LIVE_ANALYSIS = new EAnalysisState(3, 'CATCHUP_LIVE_ANALYSIS');
	static readonly ROLLBACK_ANALYSIS = new EAnalysisState(4, 'ROLLBACK_ANALYSIS');
	static readonly values = [
		EAnalysisState.INITIAL_ANALYSIS,
		EAnalysisState.HISTORY_ANALYSIS,
		EAnalysisState.LIVE_ANALYSIS,
		EAnalysisState.CATCHUP_LIVE_ANALYSIS,
		EAnalysisState.ROLLBACK_ANALYSIS
	];



	static readonly INTERMEDIATE_ANALYSIS_STATES = [
		EAnalysisState.CATCHUP_LIVE_ANALYSIS,
		EAnalysisState.HISTORY_ANALYSIS,
		EAnalysisState.ROLLBACK_ANALYSIS
	];

	private constructor (
		public readonly ordinal: number,
		public readonly name: EAnalysisStateEntry,

	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: EAnalysisStateEntry): EAnalysisState;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: EAnalysisStateEntry | undefined): EAnalysisState | undefined;
	public static valueOf(entry: EAnalysisStateEntry | undefined): EAnalysisState | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EAnalysisState[entry];
	}
}

