import { createTheme, type MantineThemeOverride, Select } from '@mantine/core';
import { Icon } from 'ts/components/Icon';

/** Overrides for the default Mantine theme. */
export const MANTINE_THEME: MantineThemeOverride = createTheme({
	primaryColor: 'blue',
	autoContrast: true,
	fontFamily: "'Open Sans', 'Adjusted Arial Fallback', Arial",
	fontFamilyMonospace: "'Ubuntu Mono'",
	respectReducedMotion: true,
	components: {
		Select: Select.extend({
			defaultProps: {
				rightSectionPointerEvents: 'none',
				rightSection: <Icon name="dropdown" className="pb-[22px]" />,
				size: 'md',
				allowDeselect: false
			}
		})
	}
});
