/** Generated from EMetricSchemaSource.java. Do not modify this file! */

import type { ETypeEntry } from 'typedefs/EType';

export type EMetricSchemaSourceEntry = 'CODE_METRICS' | 'NON_CODE_METRICS' | 'TEST_IMPLEMENTATION_METRICS' | 'TEST_EXECUTION_METRICS' | 'TEST_QUERY_METRICS' | 'ISSUE_METRICS' | 'SPEC_ITEM_METRICS';

export class EMetricSchemaSource {
	static readonly CODE_METRICS = new EMetricSchemaSource(0, 'CODE_METRICS', "CODE", "");
	static readonly NON_CODE_METRICS = new EMetricSchemaSource(1, 'NON_CODE_METRICS', "NON_CODE", "-non-code-");
	static readonly TEST_IMPLEMENTATION_METRICS = new EMetricSchemaSource(2, 'TEST_IMPLEMENTATION_METRICS', "TEST_IMPLEMENTATION", "-test-implementation-");
	static readonly TEST_EXECUTION_METRICS = new EMetricSchemaSource(3, 'TEST_EXECUTION_METRICS', "TEST_EXECUTION", "-test-execution-");
	static readonly TEST_QUERY_METRICS = new EMetricSchemaSource(4, 'TEST_QUERY_METRICS', "TEST_QUERY", "-test-query-");
	static readonly ISSUE_METRICS = new EMetricSchemaSource(5, 'ISSUE_METRICS', "ISSUE_QUERY", "-issues-");
	static readonly SPEC_ITEM_METRICS = new EMetricSchemaSource(6, 'SPEC_ITEM_METRICS', "SPEC_ITEM_QUERY", "-spec-items-");
	static readonly values = [
		EMetricSchemaSource.CODE_METRICS,
		EMetricSchemaSource.NON_CODE_METRICS,
		EMetricSchemaSource.TEST_IMPLEMENTATION_METRICS,
		EMetricSchemaSource.TEST_EXECUTION_METRICS,
		EMetricSchemaSource.TEST_QUERY_METRICS,
		EMetricSchemaSource.ISSUE_METRICS,
		EMetricSchemaSource.SPEC_ITEM_METRICS
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EMetricSchemaSourceEntry,
		public readonly pathType: ETypeEntry,
		public readonly pathPrefix: string
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: EMetricSchemaSourceEntry): EMetricSchemaSource;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: EMetricSchemaSourceEntry | undefined): EMetricSchemaSource | undefined;
	public static valueOf(entry: EMetricSchemaSourceEntry | undefined): EMetricSchemaSource | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EMetricSchemaSource[entry];
	}
}

