import clsx from 'clsx';
import { type ElementType, type ReactNode, type RefObject } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link SearchResults}. */
export type SearchResultsProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;
};

export function SearchResults(props: SearchResultsProps) {
	const { children, className, content } = props;
	const classes = clsx('results transition', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}
const handledProps = ['as', 'children', 'className', 'content'];
