/** Generated from EBasicPermissionScope.java. Do not modify this file! */

import type { EGlobalPermissionEntry } from 'typedefs/EGlobalPermission';

export type EBasicPermissionScopeEntry = 'USERS' | 'GROUPS' | 'ANALYSIS_PROFILES' | 'METRIC_THRESHOLD_CONFIGURATIONS' | 'EXTERNAL_TOOL_ACCOUNTS' | 'EXTERNAL_STORAGE_BACKENDS' | 'REPORTS' | 'RETROSPECTIVES' | 'DASHBOARDS' | 'DASHBOARD_TEMPLATES' | 'PROFILER_CONFIGURATIONS';

export class EBasicPermissionScope {
	static readonly USERS = new EBasicPermissionScope(0, 'USERS', "User", "CREATE_USERS");
	static readonly GROUPS = new EBasicPermissionScope(1, 'GROUPS', "Group", "CREATE_GROUPS");
	static readonly ANALYSIS_PROFILES = new EBasicPermissionScope(2, 'ANALYSIS_PROFILES', "Analysis Profile", "CREATE_ANALYSIS_PROFILES");
	static readonly METRIC_THRESHOLD_CONFIGURATIONS = new EBasicPermissionScope(3, 'METRIC_THRESHOLD_CONFIGURATIONS', "Metric Threshold Configuration", "CREATE_METRIC_THRESHOLD_CONFIGURATIONS");
	static readonly EXTERNAL_TOOL_ACCOUNTS = new EBasicPermissionScope(4, 'EXTERNAL_TOOL_ACCOUNTS', "External Account", "CREATE_EXTERNAL_TOOL_ACCOUNTS");
	static readonly EXTERNAL_STORAGE_BACKENDS = new EBasicPermissionScope(5, 'EXTERNAL_STORAGE_BACKENDS', "External Storage Backend", "CREATE_EXTERNAL_STORAGE_BACKENDS");
	static readonly REPORTS = new EBasicPermissionScope(6, 'REPORTS', "Quality Report", "CREATE_QUALITY_REPORTS");
	static readonly RETROSPECTIVES = new EBasicPermissionScope(7, 'RETROSPECTIVES', "Retrospectives", "CREATE_RETROSPECTIVES");
	static readonly DASHBOARDS = new EBasicPermissionScope(8, 'DASHBOARDS', "Dashboard", null);
	static readonly DASHBOARD_TEMPLATES = new EBasicPermissionScope(9, 'DASHBOARD_TEMPLATES', "Dashboard Template", "CREATE_DASHBOARD_TEMPLATES");
	static readonly PROFILER_CONFIGURATIONS = new EBasicPermissionScope(10, 'PROFILER_CONFIGURATIONS', "Profiler Configuration", "CREATE_PROFILER_CONFIGURATIONS");
	static readonly values = [
		EBasicPermissionScope.USERS,
		EBasicPermissionScope.GROUPS,
		EBasicPermissionScope.ANALYSIS_PROFILES,
		EBasicPermissionScope.METRIC_THRESHOLD_CONFIGURATIONS,
		EBasicPermissionScope.EXTERNAL_TOOL_ACCOUNTS,
		EBasicPermissionScope.EXTERNAL_STORAGE_BACKENDS,
		EBasicPermissionScope.REPORTS,
		EBasicPermissionScope.RETROSPECTIVES,
		EBasicPermissionScope.DASHBOARDS,
		EBasicPermissionScope.DASHBOARD_TEMPLATES,
		EBasicPermissionScope.PROFILER_CONFIGURATIONS
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EBasicPermissionScopeEntry,
		public readonly readableName: string,
		public readonly createPermission: EGlobalPermissionEntry | null
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: EBasicPermissionScopeEntry): EBasicPermissionScope;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: EBasicPermissionScopeEntry | undefined): EBasicPermissionScope | undefined;
	public static valueOf(entry: EBasicPermissionScopeEntry | undefined): EBasicPermissionScope | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EBasicPermissionScope[entry];
	}
}

