import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ECompareMode } from 'ts/commons/code/compare/CompareCodeComponent';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EComparePerspectiveView } from 'ts/perspectives/compare/EComparePerspectiveView';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';

/** The name of the parameter that stores the compare mode in the navigation hash. */
export const COMPARE_MODE_PARAMETER = 'compare-mode';

/** The name of the parameter that stores whether coverage should be shown in the compare view in the navigation hash. */
export const SHOW_COVERAGE = 'show-coverage';

/** Describes one side of the compare view link. */
type CompareSideWithProject = {
	project: string;
	uniformPath: string;
	commit?: UnresolvedCommitDescriptor | CommitDescriptor | null;
	startLine?: number;
	endLine?: number;
};

type FileAtCommit = {
	uniformPath: string;
	commit: UnresolvedCommitDescriptor | CommitDescriptor | null;
	startLine?: number;
};

type CompareOptions = {
	/** Optional boolean to denote if it's an inconsistent clone. */
	isInconsistentClone?: boolean;
	compareMode?: ECompareMode;
	showCoverage?: boolean;
};

type LineRange = {
	startLine: number;
	endLine: number;
};

/** Parameters used to build compare links. */
export type CompareLinkParameters = [CompareSideWithProject, CompareSideWithProject];

/** Returns a URL for comparing two locations within two files in the same project for a commit. */
export function compareFiles(
	project: string,
	leftSide: FileAtCommit,
	rightSide: FileAtCommit,
	options?: CompareOptions
): string;
export function compareFiles(
	project: string,
	leftSide: FileAtCommit & LineRange,
	rightSide: FileAtCommit & LineRange,
	options?: CompareOptions
): string;
export function compareFiles(
	project: string,
	leftSide: FileAtCommit & Partial<LineRange>,
	rightSide: FileAtCommit & Partial<LineRange>,
	options?: CompareOptions
): string {
	return comparePerspective(
		{
			project,
			...leftSide
		},
		{
			project,
			...rightSide
		},
		options
	);
}

/**
 * Returns a URL for comparing two files.
 *
 * @param leftParams
 * @param leftParams.project The source project.
 * @param leftParams.uniformPath The source uniform path.
 * @param leftParams.commit The source commit.
 * @param leftParams.startLine The source start line number.
 * @param leftParams.endLine The source end line number.
 * @param rightParams
 * @param rightParams.project The target project.
 * @param rightParams.uniformPath The target uniform path.
 * @param rightParams.commit The target commit.
 * @param rightParams.startLine The target start line number.
 * @param rightParams.endLine The target end line number.
 * @param options Additional compare view options that should be appended to the URL
 */
export function comparePerspective(
	leftParams: CompareSideWithProject,
	rightParams: CompareSideWithProject,
	options?: CompareOptions
) {
	const leftStartLine = leftParams.startLine;
	const leftEndLine = leftParams.endLine;
	const rightStartLine = rightParams.startLine;
	const rightEndLine = rightParams.endLine;

	const queryParams: Record<string, string | boolean | UnresolvedCommitDescriptor | undefined | number> = {
		'left-project': leftParams.project,
		'right-project': rightParams.project,
		'left-path': leftParams.uniformPath,
		'right-path': rightParams.uniformPath,
		'left-commit': UnresolvedCommitDescriptor.wrap(leftParams.commit) ?? undefined,
		'right-commit': UnresolvedCommitDescriptor.wrap(rightParams.commit) ?? undefined
	};
	if (leftStartLine && leftEndLine && rightStartLine && rightEndLine) {
		// Opens the compare view for a diff of regions within the two files. Only fully defined regions are supported.
		queryParams['region'] = `${leftStartLine}-${leftEndLine}:${rightStartLine}-${rightEndLine}`;
	} else if (leftStartLine) {
		// if regions to be compared are not fully defined, but left start line is known, use initial scroll feature of compare view.
		queryParams['left-line'] = leftStartLine;
	} else if (rightStartLine) {
		// if regions to be compared are not fully defined, but right start line is known, use initial scroll feature of compare view.
		queryParams['right-line'] = rightStartLine;
	}
	if (options?.isInconsistentClone) {
		queryParams['inconsistent-clone'] = options.isInconsistentClone;
	}
	if (options?.compareMode) {
		queryParams[COMPARE_MODE_PARAMETER] = options.compareMode;
	}
	if (options?.showCoverage) {
		queryParams[SHOW_COVERAGE] = options.showCoverage;
	}
	return linkTo(EComparePerspectiveView.CODE_COMPARE, queryParams);
}

/** Information about a single Spec Item compare element */
export type SpecItemCompareInformation = {
	/** ID of the underlying item */
	issueId: string;
	/** ID of the finding */
	findingId: string;
};

/** Returns a URL for comparing spec items. */
export function compareSpecItems(
	project: string,
	left: SpecItemCompareInformation,
	right: SpecItemCompareInformation,
	commit: UnresolvedCommitDescriptor
) {
	return linkTo(EComparePerspectiveView.SPEC_ITEM_COMPARE, project, {
		'left-compare': JSON.stringify(left),
		'right-compare': JSON.stringify(right),
		t: commit
	});
}

/** Returns a URL for comparing coverage of changes. */
export function compareTestCoverage(
	project: string,
	mergeRequestId: string,
	originPath: string,
	path: string,
	commit: UnresolvedCommitDescriptor
) {
	return linkTo(EComparePerspectiveView.TEST_COVERAGE_COMPARE, project, {
		'merge-request': mergeRequestId,
		'origin-path': originPath,
		path,
		t: commit
	});
}
