import { useEffect, useRef } from 'react';

/**
 * Hook keeping track of a given value from a previous execution of the component the Hook is used in.
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>(undefined);

	useEffect(() => {
		ref.current = value;
	});

	// eslint-disable-next-line react-compiler/react-compiler
	return ref.current;
}
