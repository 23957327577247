import clsx from 'clsx';
import { type ComponentPropsWithRef, type Ref } from 'react';
import type { DataAttributes } from 'ts/components/Generic';

type BasicTableRowProps = DataAttributes & {
	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: Ref<HTMLTableRowElement>;

	/** Additional classes. */
	className?: string;

	/** A row can be disabled. */
	disabled?: boolean;

	/** A row may call attention to an error or a negative value. */
	error?: boolean;

	/** A row may warn a user. */
	warning?: boolean;
};

/** Props for the TableRow. */
export type TableRowProps = ComponentPropsWithRef<'tr'> & BasicTableRowProps;

/** The tr element of a table. */
export function TableRow({ error, warning, disabled, className, ...props }: TableRowProps) {
	return (
		<tr
			className={clsx(
				{
					error,
					warning,
					disabled
				},
				className
			)}
			{...props}
		/>
	);
}
