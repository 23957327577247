import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Defines all subviews of the Findings perspective */
export const EFindingsPerspectiveView = {
	/** Shows the list of all findings in the selected project. */
	CODE_FINDINGS_LIST: {
		perspective: ETeamscalePerspective.FINDINGS,
		name: 'Code Findings',
		anchor: 'list',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./list/CodeFindingListView'),
		timeTravel: {
			contentName: 'Code Findings'
		}
	} as ViewDescriptor,
	FINDING_DETAIL: {
		perspective: ETeamscalePerspective.FINDINGS,
		name: 'Finding',
		anchor: 'details',
		requiresProject: true,
		hasSelfManagedTitle: true,
		projectIsFixed: true,
		view: () => import('./details/FindingDetailView'),
		timeTravel: {
			contentName: 'Finding Details'
		}
	},
	SPEC_ITEM_FINDINGS_LIST: {
		perspective: ETeamscalePerspective.FINDINGS,
		name: 'Spec Item Findings',
		anchor: 'spec-item-findings',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./list/SpecItemFindingListView'),
		timeTravel: {
			contentName: 'Spec Item Findings'
		}
	} as ViewDescriptor,
	/** Shows the checks that are configured for the selected project. */
	RULES: {
		perspective: ETeamscalePerspective.FINDINGS,
		name: 'Checks',
		anchor: 'checks',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('ts/commons/checks/ChecksView')
	} as ViewDescriptor
} as const;
