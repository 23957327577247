import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { ERequirementsPerspectiveView } from 'ts/perspectives/requirement_tracing/ERequirementsPerspectiveView';
import type { TeamscaleIssueId } from 'typedefs/TeamscaleIssueId';

/** Returns a URL for a spec item query. */
export function specItemQuery(
	project: string,
	options?: { query?: string; queryName?: string; specItemTab?: 0 | 1 | 2 | 3 }
) {
	return linkTo(ERequirementsPerspectiveView.OVERVIEW, project, {
		q: options?.query,
		queryname: options?.queryName,
		specItemTab: options?.specItemTab
	});
}

/** Returns a URL for a spec item */
export function specItem(
	project: string,
	id: TeamscaleIssueId | string,
	params?: {
		tracingTab?: number;
		commit?: UnresolvedCommitDescriptor | null;
	}
) {
	return linkTo(ERequirementsPerspectiveView.DETAILS, project, {
		t: params?.commit,
		id: asIssueId(id),
		tracingTab: params?.tracingTab
	});
}

/** Returns the internal ID if the id is given as {@link TeamscaleIssueId} or the plain string otherwise. */
export function asIssueId(id: TeamscaleIssueId | string): string {
	if (typeof id === 'object') {
		return id.internalId;
	} else {
		return id;
	}
}
