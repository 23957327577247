import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Defines all sub-views of the audit perspective */
export const EAuditPerspectiveView = {
	FILE_SUMMARY: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'File Summary',
		anchor: 'file-summary',
		requiresProject: false,
		visibleInSidebar: true,
		view: () => import('./FileSummaryView')
	} as ViewDescriptor,
	RANDOM_FILE_PICKER: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Random File Picker',
		anchor: 'random',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./RandomFilePickerView')
	} as ViewDescriptor,
	FILES_WITH_IDENTICAL_NAME: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Identical File Names',
		anchor: 'identical-file-names',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./FilesWithIdenticalNameView')
	} as ViewDescriptor,
	FILES_WITH_IDENTICAL_CONTENT: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Identical File Contents',
		anchor: 'identical-file-contents',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./FilesWithIdenticalContentView')
	} as ViewDescriptor,
	DATA_EXPORT: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Data Export',
		anchor: 'data-export',
		requiresProject: true,
		visibleInSidebar: true,
		viewCreator: () => import('./export/AuditDataExportView')
	} as ViewDescriptor,
	REDUNDANT_LITERALS: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Redundant Literals',
		anchor: 'redundant-literals',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./RedundantLiteralView')
	} as ViewDescriptor,
	BENCHMARK: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Benchmark',
		anchor: 'benchmark',
		requiresProject: false,
		visibleInSidebar: true,
		viewCreator: () => import('./benchmark/BenchmarkView')
	} as ViewDescriptor,
	LICENSE_VIEW: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'License View',
		anchor: 'license',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./LicenseView')
	} as ViewDescriptor,
	CODE_COMPONENTS_TREEMAP: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Code Components',
		anchor: 'code-components-treemap',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./CodeComponentsView')
	} as ViewDescriptor,
	TECHNOLOGY_SCAN: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Technology Scan',
		anchor: 'technology',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./TechnologyView')
	} as ViewDescriptor,
	EXTERNAL_X_CLONES: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'External X-Clones',
		anchor: 'x-clones',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./ExternalXClonesView')
	} as ViewDescriptor,
	EXCEPTION_HIERARCHY: {
		perspective: ETeamscalePerspective.AUDIT,
		name: 'Exceptions Hierarchy',
		anchor: 'exceptions-hierarchy',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./ExceptionHierarchyView')
	} as ViewDescriptor,
	DOTNET_SOURCE_PATHS_EXTRACTOR: {
		perspective: ETeamscalePerspective.AUDIT,
		name: '.NET Source Paths Extractor',
		anchor: 'dotnet-source-paths-extractor',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('./DotNetSourcePathsExtractorView')
	} as ViewDescriptor
} as const;
