/** Generated from ETrafficLightColor.java. Do not modify this file! */


export type ETrafficLightColorEntry = 'RED' | 'ORANGE' | 'YELLOW' | 'GREEN' | 'BASELINE' | 'UNKNOWN';

export class ETrafficLightColor {
	static readonly RED = new ETrafficLightColor(0, 'RED', "Red", "Red", "#dd513a", "#dd513a");
	static readonly ORANGE = new ETrafficLightColor(1, 'ORANGE', "Orange", "Orange", "#ff9966", "#5a908c");
	static readonly YELLOW = new ETrafficLightColor(2, 'YELLOW', "Yellow", "Yellow", "#fde725", "#fde725");
	static readonly GREEN = new ETrafficLightColor(3, 'GREEN', "Green", "Green", "#44bf70", "#77afd8");
	static readonly BASELINE = new ETrafficLightColor(4, 'BASELINE', "Baseline", "Baseline", "#dfeaf4", "#cddeef");
	static readonly UNKNOWN = new ETrafficLightColor(5, 'UNKNOWN', "Unknown", "", "#666666", "#666666");
	static readonly values = [
		ETrafficLightColor.RED,
		ETrafficLightColor.ORANGE,
		ETrafficLightColor.YELLOW,
		ETrafficLightColor.GREEN,
		ETrafficLightColor.BASELINE,
		ETrafficLightColor.UNKNOWN
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETrafficLightColorEntry,
		public readonly displayText: string,
		public readonly assessmentDisplayName: string,
		public readonly hexValue: string,
		public readonly colorBlindHexValue: string,

	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: ETrafficLightColorEntry): ETrafficLightColor;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: ETrafficLightColorEntry | undefined): ETrafficLightColor | undefined;
	public static valueOf(entry: ETrafficLightColorEntry | undefined): ETrafficLightColor | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return ETrafficLightColor[entry];
	}
}

