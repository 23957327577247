import { linkTo } from 'ts/commons/links/LinkTo';
import { ESearchPerspectiveView } from 'ts/perspectives/search/ESearchPerspectiveView';

/**
 * Returns a URL to search results.
 *
 * @param project The project to search in.
 * @param query The query that is to be searched.
 */
export function searchResults(project: string | undefined, query: string) {
	return linkTo(ESearchPerspectiveView.SEARCH, project, { query });
}

/** Returns a URL to search view */
export function searchView() {
	return linkTo(ESearchPerspectiveView.SEARCH);
}
