import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type RefObject } from 'react';
import { getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link DropdownDivider}. */
export type DropdownDividerProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Additional classes. */
	className?: string;
};

/** A dropdown menu can contain dividers to separate related content. */
export function DropdownDivider(props: DropdownDividerProps) {
	const { className } = props;

	const classes = clsx('divider', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return <ElementType {...rest} className={classes} />;
}
const handledProps = ['as', 'className'];
