import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 *
 * Modified `useCallback` that can be used when dependencies change too frequently. Can occur when: e.g. user props are
 * depedencies which could change on every render e.g. volatile values (i.e. useState/useDispatch) are dependencies
 * which could change frequently
 *
 * This should not be used often, but can be a useful re-render optimization since the callback is a ref and will not be
 * invalidated between rerenders.
 *
 * @param fn The callback function that will be used
 */
export function useEventCallback<Args extends unknown[], R>(fn: (...args: Args) => R): (...args: Args) => R;
export function useEventCallback<Args extends unknown[], R>(
	fn: ((...args: Args) => R) | undefined
): ((...args: Args) => R) | undefined;
export function useEventCallback<Args extends unknown[], R>(
	fn: ((...args: Args) => R) | undefined
): ((...args: Args) => R) | undefined {
	const ref = useRef<typeof fn>(() => {
		throw new Error('Cannot call an event handler while rendering.');
	});

	useLayoutEffect(() => {
		ref.current = fn;
	}, [fn]);

	return useCallback((...args: Args) => ref.current?.(...args), [ref]) as (...args: Args) => R;
}
