import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import { type ComponentPropsWithoutRef, type ElementType, type ReactNode, type RefObject } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticCOLORS, SemanticShorthandContent, SemanticShorthandItem } from '../Generic';
import { childrenUtils, createHTMLDivision, getComponentType, getUnhandledProps, keyOnly, valueAndKey } from '../lib';

/** Props for {@link Progress}. */
export type ProgressProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;

		/** A progress bar can show activity. */
		active?: boolean;

		/** A progress bar can attach to and show the progress of an element (i.e. Card or Segment). */
		attached?: 'top' | 'bottom';

		/** Whether success state should automatically trigger when progress completes. */
		autoSuccess?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** A progress bar can have different colors. */
		color?: SemanticCOLORS;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** A progress bar be disabled. */
		disabled?: boolean;

		/** A progress bar can show a error state. */
		error?: boolean;

		/** An indicating progress bar visually indicates the current level of progress of a task. */
		indicating?: boolean;

		/** A progress bar can have its colors inverted. */
		inverted?: boolean;

		/** Can be set to either to display progress as percent or ratio. */
		label?: SemanticShorthandItem<ComponentPropsWithoutRef<'div'>>;

		/** Current percent complete. */
		percent?: number;

		/** Decimal point precision for calculated progress. */
		precision?: number;

		/** A progress bar can contain a text value indicating current progress. */
		progress?: boolean | 'percent' | 'ratio' | 'value';

		/** A progress bar can vary in size. */
		size?: 'tiny' | 'small' | 'medium' | 'large' | 'big';

		/** A progress bar can show a success state. */
		success?: boolean;

		/** For use with value. Together, these will calculate the percent. Mutually excludes percent. */
		total?: number;

		/** For use with total. Together, these will calculate the percent. Mutually excludes percent. */
		value?: number;

		/** A progress bar can show a warning state. */
		warning?: boolean;
	}
>;

function calculatePercent(percent: number | undefined, total: number | undefined, value: number | undefined): number {
	if (!_.isUndefined(percent)) {
		return percent;
	}

	if (!_.isUndefined(total) && !_.isUndefined(value)) {
		return (value / total) * 100;
	}

	return 0;
}

function getPercent(
	percent: number | undefined,
	total: number | undefined,
	value: number | undefined,
	progress: boolean | 'percent' | 'ratio' | 'value' | undefined,
	precision: number | undefined
): number | undefined {
	const clampedPercent = _.clamp(calculatePercent(percent, total, value), 0, 100);

	if (!_.isUndefined(total) && !_.isUndefined(value) && progress === 'value') {
		return (value / total) * 100;
	}

	if (progress === 'value') {
		return value;
	}

	if (_.isUndefined(precision)) {
		return clampedPercent;
	}

	return _.round(clampedPercent, precision);
}

/** A progress bar shows the progression of a task. */
export function Progress(props: ProgressProps) {
	const {
		active,
		autoSuccess,
		attached,
		children,
		className,
		color,
		content,
		disabled,
		error,
		indicating,
		inverted,
		label,
		percent,
		precision,
		progress,
		total,
		size,
		success,
		value,
		warning
	} = props;

	const calculatedPercent = getPercent(percent, total, value, progress, precision) || 0;
	const isAutoSuccess = autoSuccess && (Number(percent) >= 100 || Number(value) >= Number(total));

	const computeValueText = () => {
		if (progress === 'value') {
			return value;
		}

		if (progress === 'ratio') {
			return `${value}/${total}`;
		}

		return `${calculatedPercent}%`;
	};

	const renderLabel = () => {
		if (!childrenUtils.isNil(children)) {
			return <div className="label">{children}</div>;
		}

		if (!childrenUtils.isNil(content)) {
			return <div className="label">{content}</div>;
		}

		return createHTMLDivision(label, {
			autoGenerateKey: false,
			defaultProps: { className: 'label' }
		});
	};

	const renderProgress = () => {
		if (!progress && _.isUndefined(precision)) {
			return;
		}

		return <div className="progress">{computeValueText()}</div>;
	};

	const classes = clsx(
		'ui',
		color,
		size,
		keyOnly(active || indicating, 'active'),
		keyOnly(disabled, 'disabled'),
		keyOnly(error, 'error'),
		keyOnly(indicating, 'indicating'),
		keyOnly(inverted, 'inverted'),
		keyOnly(success || isAutoSuccess, 'success'),
		keyOnly(warning, 'warning'),
		valueAndKey(attached, 'attached'),
		'progress',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes} data-percent={Math.floor(calculatedPercent)}>
			<div className="bar" style={{ width: `${calculatedPercent}%` }}>
				{renderProgress()}
			</div>
			{renderLabel()}
		</ElementType>
	);
}
const handledProps = [
	'active',
	'as',
	'attached',
	'autoSuccess',
	'children',
	'className',
	'color',
	'content',
	'disabled',
	'error',
	'indicating',
	'inverted',
	'label',
	'percent',
	'precision',
	'progress',
	'size',
	'success',
	'total',
	'value',
	'warning'
];
