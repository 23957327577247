import clsx from 'clsx';
import { type ElementType, type ReactNode, type RefObject } from 'react';
import type { SemanticWIDTHS } from '../Generic';
import { getComponentType, getUnhandledProps, keyOnly, widthProp } from '../lib';

/** Props for {@link FormGroup}. */
export type FormGroupProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** A Form Group can be disabled. */
	disabled?: boolean;

	/** A Form Group can have error. */
	error?: boolean;

	/** Fields can show related choices. */
	grouped?: boolean;

	/** Multiple fields may be inline in a row. */
	inline?: boolean;

	/** A form group can prevent itself from stacking on mobile. */
	unstackable?: boolean;

	/** Fields Groups can specify their width in grid columns or automatically divide fields to be equal width. */
	widths?: SemanticWIDTHS | 'equal';
};

/**
 * A set of fields can appear grouped together.
 *
 * @see Form
 */
export function FormGroup(props: FormGroupProps) {
	const { children, className, disabled, error, grouped, inline, unstackable, widths } = props;

	const classes = clsx(
		keyOnly(error, 'error'),
		keyOnly(disabled, 'disabled'),
		keyOnly(grouped, 'grouped'),
		keyOnly(inline, 'inline'),
		keyOnly(unstackable, 'unstackable'),
		widthProp(widths, null, true),
		'fields',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{children}
		</ElementType>
	);
}
const handledProps = ['as', 'children', 'className', 'grouped', 'inline', 'unstackable', 'widths'];
