import { type ElementType, type RefObject } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { Dropdown, type DropdownProps } from '../Dropdown';
import { getComponentType, getUnhandledProps } from '../lib';
import { type BasicFormFieldProps, FormField } from './FormField';

/** Props for {@link FormDropdown}. */
export type FormDropdownProps = ExtendTypeWith<
	ExtendTypeWith<DropdownProps, BasicFormFieldProps>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;
	}
>;

/**
 * Sugar for <Form.Field control={Dropdown} />.
 *
 * @see Dropdown
 * @see Form
 */
export function FormDropdown(props: FormDropdownProps) {
	const { control = Dropdown } = props;

	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: FormField });

	return <ElementType {...rest} control={control} />;
}
const handledProps = ['as', 'control'];
