import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { ReportWarning } from 'ts/perspectives/quality_control/reports/views/ReportWarning';
import { EConfigurationFeature } from 'typedefs/EConfigurationFeature';
import { EFeatureToggle } from 'typedefs/EFeatureToggle';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

function canConfigureQualityReports(context: ExtendedPerspectiveContext): boolean {
	return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_QUALITY_REPORTS);
}

function canConfigureRetrospectives(context: ExtendedPerspectiveContext): boolean {
	return PermissionUtils.mayAccessFeature(context, EConfigurationFeature.CONFIGURE_RETROSPECTIVES);
}

function canViewRetrospectives(context: ExtendedPerspectiveContext): boolean {
	return canConfigureRetrospectives(context);
}

export const EQualityPerspectiveView = {
	REPORTS: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Reports',
		anchor: 'reports',
		requiresProject: true,
		showAllProjects: true,
		visibleInSidebar: true,
		view: () => import('./reports/views/ReportListView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_SHOW: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Reports',
		anchor: 'reports',
		action: 'view',
		requiresProject: false,
		viewCreator: () => import('./reports/views/ReportShowView'),
		additionalPerspectiveSettingsComponent: () => <ReportWarning />,
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_EDIT: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Reports',
		anchor: 'reports',
		action: 'edit',
		requiresProject: false,
		viewCreator: () => import('./reports/views/ReportEditorView'),
		additionalPerspectiveSettingsComponent: () => <ReportWarning addSlideInaccessiblePlaceholder />,
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_PRINT: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Reports',
		anchor: 'reports',
		action: 'print',
		requiresProject: false,
		viewCreator: () => import('./reports/views/PrintReportView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_PRESENT: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Reports',
		anchor: 'reports',
		action: 'present',
		requiresProject: false,
		viewCreator: () => import('./reports/views/PresentReportView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	REPORT_ROLES: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Reports',
		anchor: 'reports',
		action: 'report-roles',
		requiresProject: false,
		view: () => import('./reports/views/ReportRolesView'),
		canBeAccessed: canConfigureQualityReports
	} as ViewDescriptor,
	BASELINES: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Baselines',
		anchor: 'baselines',
		requiresProject: true,
		visibleInSidebar: true,
		view: () => import('../findings/baselines/BaselineView')
	} as ViewDescriptor,
	TASKS: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Tasks',
		anchor: 'tasks',
		requiresProject: true,
		visibleInSidebar: true,
		timeTravel: {
			contentName: 'Tasks',
			hideTimeTravelButton: true
		},
		view: () => import('./tasks/list/TaskListView')
	} as ViewDescriptor,
	TASK_NEW: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Tasks',
		anchor: 'tasks',
		action: 'new',
		requiresProject: true,
		projectIsFixed: true,
		view: () => import('./tasks/detail/TaskEditView')
	} as ViewDescriptor,
	TASK_DETAIL: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Tasks',
		anchor: 'tasks',
		action: 'details',
		requiresProject: true,
		projectIsFixed: true,
		timeTravel: {
			contentName: 'Tasks',
			hideTimeTravelButton: true
		},
		view: () => import('./tasks/detail/TaskDetailsView')
	} as ViewDescriptor,
	TASK_EDIT: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Tasks',
		anchor: 'tasks',
		action: 'edit',
		requiresProject: true,
		projectIsFixed: true,
		view: () => import('./tasks/detail/TaskEditView')
	} as ViewDescriptor,
	RETROSPECTIVES: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Retrospectives',
		anchor: 'retrospectives',
		requiresProject: true,
		showAllProjects: true,
		visibleInSidebar: canViewRetrospectives,
		isBeta: true,
		view: () => import('./retrospectives/views/RetrospectiveListView'),
		canBeAccessed: canConfigureRetrospectives
	} as ViewDescriptor,
	RETROSPECTIVE_EDIT: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Retrospectives',
		anchor: 'retrospectives',
		action: 'edit',
		requiresProject: false,
		projectIsFixed: true,
		view: () => import('./retrospectives/views/RetrospectiveEditView'),
		canBeAccessed: canConfigureRetrospectives
	} as ViewDescriptor,
	RETROSPECTIVE_ROLES: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Retrospectives',
		anchor: 'retrospectives',
		action: 'retrospective-roles',
		requiresProject: false,
		view: () => import('./retrospectives/views/RetrospectiveRolesView'),
		canBeAccessed: canConfigureRetrospectives
	} as ViewDescriptor,
	PORTFOLIO_OVERVIEW: {
		perspective: ETeamscalePerspective.QUALITY_CONTROL,
		name: 'Portfolios',
		anchor: 'portfolios',
		isBeta: true,
		visibleInSidebar: true,
		requiresProject: true,
		hideProjectSelector: true,
		view: () => import('./portfolio/PortfolioOverview'),
		canBeAccessed: context =>
			context.teamscaleInfo.enabledFeatureToggles.includes(EFeatureToggle.PORTFOLIO_OVERVIEW.name)
	} as ViewDescriptor
} as const;
