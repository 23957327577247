import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ReactNode, type RefObject } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link MessageItem}. */
export type MessageItemProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;
	}
>;

/** A message list can contain an item. */
export function MessageItem(props: MessageItemProps) {
	const { children, className, content } = props;

	const classes = clsx('content', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, { defaultAs: 'li' });

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}

export const createMessageItem = createShorthandFactory(MessageItem, content => ({ content }));
const handledProps = ['as', 'children', 'className', 'content'];
