import clsx from 'clsx';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type MouseEvent,
	type ReactNode,
	type RefObject
} from 'react';
import type {
	SemanticFLOATS,
	SemanticShorthandCollection,
	SemanticShorthandContent,
	SemanticSIZES,
	SemanticVERTICALALIGNMENTS
} from '../Generic';

import {
	childrenUtils,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	valueAndKey,
	verticalAlignProp
} from '../lib';
import { createListItem, type ListItemProps } from './ListItem';

/** Props for {@link List}. */
export type ListProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** A list can animate to set the current item apart from the list. */
	animated?: boolean;

	/** A list can mark items with a bullet. */
	bulleted?: boolean;

	/** A list can divide its items into cells. */
	celled?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A list can show divisions between content. */
	divided?: boolean;

	/** An list can be floated left or right. */
	floated?: SemanticFLOATS;

	/** A list can be formatted to have items appear horizontally. */
	horizontal?: boolean;

	/** A list can be inverted to appear on a dark background. */
	inverted?: boolean;

	/** Shorthand array of props for ListItem. */
	items?: SemanticShorthandCollection<ListItemProps>;

	/** A list can be specially formatted for navigation links. */
	link?: boolean;

	/** OnClick handler for ListItem. Mutually exclusive with children. */
	onItemClick?: (event: MouseEvent<HTMLAnchorElement>, data: ListItemProps) => void;

	/** A list can be ordered numerically. */
	ordered?: boolean;

	/** A list can relax its padding to provide more negative space. */
	relaxed?: boolean | 'very';

	/** A selection list formats list items as possible choices. */
	selection?: boolean;

	/** A list can vary in size. */
	size?: SemanticSIZES;

	/** An element inside a list can be vertically aligned. */
	verticalAlign?: SemanticVERTICALALIGNMENTS;
};

/** A list groups related content. */
export function List(props: ListProps) {
	const {
		animated,
		bulleted,
		celled,
		children,
		className,
		content,
		divided,
		floated,
		horizontal,
		inverted,
		items,
		link,
		ordered,
		relaxed,
		selection,
		size,
		verticalAlign
	} = props;

	const classes = clsx(
		'ui',
		size,
		keyOnly(animated, 'animated'),
		keyOnly(bulleted, 'bulleted'),
		keyOnly(celled, 'celled'),
		keyOnly(divided, 'divided'),
		keyOnly(horizontal, 'horizontal'),
		keyOnly(inverted, 'inverted'),
		keyOnly(link, 'link'),
		keyOnly(ordered, 'ordered'),
		keyOnly(selection, 'selection'),
		keyOrValueAndKey(relaxed, 'relaxed'),
		valueAndKey(floated, 'floated'),
		verticalAlignProp(verticalAlign),
		'list',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType role="list" {...rest} className={classes}>
				{children}
			</ElementType>
		);
	}

	if (!childrenUtils.isNil(content)) {
		return (
			<ElementType role="list" {...rest} className={classes}>
				{content}
			</ElementType>
		);
	}

	return (
		<ElementType role="list" {...rest} className={classes}>
			{items?.map(item =>
				createListItem(item, {
					overrideProps: predefinedProps => ({
						onClick: (e, itemProps) => {
							predefinedProps.onClick?.(e, itemProps);
							props.onItemClick?.(e, itemProps);
						}
					})
				})
			)}
		</ElementType>
	);
}
const handledProps = [
	'animated',
	'as',
	'bulleted',
	'celled',
	'children',
	'className',
	'content',
	'divided',
	'floated',
	'horizontal',
	'inverted',
	'items',
	'link',
	'onItemClick',
	'ordered',
	'relaxed',
	'selection',
	'size',
	'verticalAlign'
];
