import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Defines all sub-views of the Snippet Analysis perspective */
export const ESnippetAnalysisPerspectiveView = {
	/** The snippet analysis view. */
	SNIPPET_ANALYSIS: {
		perspective: ETeamscalePerspective.SNIPPET_ANALYSIS,
		name: 'Snippet Analysis',
		anchor: 'snippet-analysis',
		requiresProject: true,
		view: () => import('./SnippetAnalysisView')
	} as ViewDescriptor
};
