import { type JSX } from 'react';
import { TeamscaleViewWrapper } from 'ts/base/TeamscaleViewWrapper';
import { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import type { ViewDescriptor, ViewFactoryModule } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/** Factory method that returns the view component to be rendered based on the view descriptor and navigation hash. */
export async function createViewComponent(
	context: ExtendedPerspectiveContext,
	hash: NavigationHash,
	viewDescriptor: ViewDescriptor
): Promise<JSX.Element> {
	if ('viewFactory' in viewDescriptor) {
		return createViewComponentViaFactory(viewDescriptor, await viewDescriptor.viewFactory(), hash, context);
	}
	if ('viewCreator' in viewDescriptor) {
		const viewModule = await viewDescriptor.viewCreator();
		return createViewComponentFromTeamscaleViewBase(viewDescriptor, new viewModule.default(), hash, context);
	}
	const { default: View } = await viewDescriptor.view();
	return <View />;
}

async function createViewComponentViaFactory(
	viewDescriptor: ViewDescriptor,
	viewFactoryModule: ViewFactoryModule,
	hash: NavigationHash,
	context: ExtendedPerspectiveContext
) {
	const viewCreatorInstance = new viewFactoryModule.default();
	const viewInstanceOrComponent = await viewCreatorInstance.createView(hash, context);
	if (viewInstanceOrComponent instanceof TeamscaleViewBase) {
		return createViewComponentFromTeamscaleViewBase(viewDescriptor, viewInstanceOrComponent, hash, context);
	}
	return viewInstanceOrComponent;
}

async function createViewComponentFromTeamscaleViewBase(
	viewDescriptor: ViewDescriptor,
	viewInstance: TeamscaleViewBase,
	hash: NavigationHash,
	context: ExtendedPerspectiveContext
) {
	viewInstance.setViewDescriptor(viewDescriptor);
	return <TeamscaleViewWrapper view={viewInstance} hash={hash} context={context} />;
}
