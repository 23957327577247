import { linkTo } from 'ts/commons/links/LinkTo';
import { EApiPerspectiveView } from 'ts/perspectives/api/EApiPerspectiveView';

/** Returns a URL for showing the public Open API documentation. */
export function showPublicApiDocumentation() {
	return linkTo(EApiPerspectiveView.PUBLIC_API);
}

/** Returns a URL for showing the internal Open API documentation. */
export function showInternalApiDocumentation() {
	return linkTo(EApiPerspectiveView.INTERNAL_API);
}
