import { useSuspenseQuery } from '@tanstack/react-query';
import { type JSX } from 'react';
import { Outlet } from 'react-router-dom';
import { useCustomCss } from 'ts/base/hooks/UseCustomCss';
import { useKeyboardShortcutRegistry } from 'ts/base/hooks/UseKeyboardShortcut';
import { LeftSidebar } from 'ts/base/perspective/sidebar/left/LeftSidebar';
import { StaticPerspectiveContextProviders } from 'ts/base/ReactUtils';
import { EXTENDED_PERSPECTIVE_CONTEXT_QUERY } from 'ts/base/services/PerspectiveContext';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { useWhatsNewNotification } from 'ts/base/UseWhatsNewNotification';

/** Handles drawing the skeleton of a perspective including the left sidebar. */
export function TeamscalePerspectiveRoot(): JSX.Element {
	const perspectiveContext = useSuspenseQuery(EXTENDED_PERSPECTIVE_CONTEXT_QUERY).data;
	useCustomCss(perspectiveContext.teamscaleInfo.customCss);
	useWhatsNewNotification(perspectiveContext);
	useKeyboardShortcutRegistry();
	return (
		<div className="ts-root-main" key="ts-root-main">
			<StaticPerspectiveContextProviders key="perspectiveContext" perspectiveContext={perspectiveContext}>
				<LeftSidebar key="sidebar" />
				<SuspendingErrorBoundary>
					<Outlet />
				</SuspendingErrorBoundary>
			</StaticPerspectiveContextProviders>
		</div>
	);
}
