import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { FetchOptions, Method } from './ServiceClientImplementation';
import { ServiceClientImplementation } from './ServiceClientImplementation';

/** Defines all the data that needs to be available at runtime to perform the service call. */
export type OperationInfo = {
	/** The HTTP method of the operation. */
	method: Method;

	/**
	 * The API path to call. This path by definition begins with /api and contains placeholders for the path parameters
	 * in braces.
	 */
	path: string;

	/** The content type that the service expects to receive. */
	contentType?: ContentType;

	/** The media type that the service client expects to receive as response. */
	acceptType?: AcceptType;
};

/** All MIME types, which should be treated as Blob response, i.e., should not be treated as string. */
const BLOB_TYPES = ['text/csv', 'application/zip', 'application/xml'];

/** All MIME types that the service client currently supports for serialization. */
type ContentType = 'application/json' | 'multipart/form-data' | 'application/x-www-form-urlencoded' | undefined;

/** All MIME types that the service client currently supports for deserialization. */
type AcceptType =
	| 'application/json'
	| 'application/zip'
	| 'application/xml'
	| 'text/markdown'
	| 'text/html'
	| 'text/plain'
	| 'text/csv'
	| 'image/*'
	| 'image/svg+xml'
	| undefined;

/** All types that can be serialized to a path parameter or a single query or form data parameter. */
type BasicSerializableValue = string | number | boolean | UnresolvedCommitDescriptor;

/** All types that can be serialized as query parameters (including url encoded form data). */
type SerializableValue = BasicSerializableValue | object | BasicSerializableValue[] | undefined;

/** All parameters needed to perform any kind of service request. */
export type RequestParameters = {
	/** Mapping from the name of the path parameter to its value. */
	pathParams?: Record<string, BasicSerializableValue>;

	/** Mapping from the query parameter name to its value. */
	queryParams?: Record<string, SerializableValue>;

	/** The body of the request. */
	body?: unknown;

	/** Is called with the progress of the upload. */
	uploadProgressCallback?: (event: ProgressEvent) => void;
};

/** Base class for proxy implementations that need to perform service calls under the hood. */
export class ServiceCallOperationHandlerBase {
	/** Performs the given service call and returns a promise. */
	protected async performServiceCall<TData>(
		operationInfo: OperationInfo,
		parameters: RequestParameters = {},
		fetchOptions?: FetchOptions
	): Promise<TData> {
		const data = ServiceCallOperationHandlerBase.serializeData(parameters.body, operationInfo.contentType);
		return ServiceClientImplementation.call(
			operationInfo.method,
			this.resolveUrl(operationInfo.path, parameters),
			{
				contentType:
					operationInfo.contentType === 'multipart/form-data' ? undefined : operationInfo.contentType,
				acceptType: operationInfo.acceptType,
				responseType: BLOB_TYPES.includes(operationInfo.acceptType ?? 'application/json') ? 'blob' : undefined,
				...fetchOptions
			},
			data
		);
	}

	/** Serializes the given body to a XHR compatible format. */
	private static serializeData(body: unknown, contentType: ContentType): XMLHttpRequestBodyInit | undefined {
		if (contentType === 'application/x-www-form-urlencoded') {
			return ServiceCallOperationHandlerBase.convertObjectToURLSearchParams(
				body as Record<string, SerializableValue>
			);
		} else if (contentType === 'multipart/form-data') {
			return ServiceCallOperationHandlerBase.convertObjectToFormData(body as Record<string, unknown>);
		} else if (contentType === 'application/json') {
			return JSON.stringify(body);
		}
		return undefined;
	}

	/** Resolves an operation to a URL by applying path and query parameters. */
	protected resolveUrl(path: string, parameters: RequestParameters) {
		let query = ServiceCallOperationHandlerBase.convertObjectToURLSearchParams(parameters.queryParams).toString();
		if (query) {
			query = `?${query}`;
		}
		return this.resolvePath(path, parameters.pathParams) + query;
	}

	/** Resolves an operation path by replacing all path parameter placeholders with their given values. */
	protected resolvePath(path: string, pathParams: RequestParameters['pathParams']) {
		const replacer = (key: string) => encodeURIComponent(String(pathParams![key.slice(1, -1)]!));
		return path.slice(1).replace(/\{\w*}/g, replacer);
	}

	/** Converts a data object to a URLSearchParams object. */
	private static convertObjectToURLSearchParams(parameters: RequestParameters['queryParams']): URLSearchParams {
		const searchParams = new URLSearchParams();
		if (parameters == null) {
			return searchParams;
		}
		for (const [key, value] of Object.entries(parameters)) {
			if (Array.isArray(value)) {
				for (const item of value) {
					searchParams.append(key, String(item));
				}
			} else if (value != null) {
				let stringified;
				if (value.toString === Object.prototype.toString) {
					// avoid "[object Object]"
					stringified = JSON.stringify(value);
				} else {
					// eslint-disable-next-line @typescript-eslint/no-base-to-string
					stringified = String(value);
				}
				searchParams.append(key, stringified);
			}
		}
		return searchParams;
	}

	/**
	 * Converts the data from an object to a FormData format. Necessary to be able to properly pass files as request
	 * parameters.
	 */
	private static convertObjectToFormData(object: Record<string, unknown>): FormData {
		const data = new FormData();
		for (const [key, value] of Object.entries(object)) {
			if (Array.isArray(value)) {
				for (const item of value) {
					if (item instanceof Blob) {
						data.append(key, item);
					} else {
						data.append(key, String(item));
					}
				}
			} else if (value instanceof Blob) {
				data.append(key, value);
			} else {
				data.append(key, String(value));
			}
		}
		return data;
	}
}
