import { keepPreviousData, useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { type JSX, useEffect } from 'react';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { CommitSelector } from 'ts/commons/CommitSelector';
import type {
	ExposedPathEntitySelectionContext,
	PathEntitySelectionContextProviderProps
} from 'ts/commons/dialog/PathEntitySelectionContext';
import {
	PathEntitySelectionContextProvider,
	usePathEntitySelectionContext
} from 'ts/commons/dialog/PathEntitySelectionContext';
import styles from 'ts/commons/dialog/PathEntitySelectionModal.module.css';
import { PlaceholderList } from 'ts/commons/Placeholders';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { Button } from 'ts/components/Button';
import { Icon } from 'ts/components/Icon';
import { Input } from 'ts/components/Input';
import { List, ListItem } from 'ts/components/List';
import { Message } from 'ts/components/Message';
import { ModalActionButtons } from 'ts/components/Modal';
import { EType } from 'typedefs/EType';

/** A boolean check result along with a reason for or against a `true` result. */
export type PathCheckResult = {
	isAllowed: boolean;
	reason?: string;
};

type PathEntitySelectionModalInternalProps = {
	/** Callback for when the project/path/commit was selected and the dialog is closed using the "OK" button */
	onSave: (project: string | undefined, path: UniformPath, commit: UnresolvedCommitDescriptor) => void;

	/** Callback for when the dialog is closed using the "OK", "Cancel" or X buttons */
	onClose: () => void;

	/** Disables the commit selector, meaning it is not shown in the dialog */
	disableCommitSelector?: boolean;

	/** Path ETypes that should not be displayed */
	forbiddenUniformPathTypes?: EType[];
};

type FileListProps = {
	forbiddenUniformPathTypes: EType[];
};

/** Main properties for the path entity selection modal */
type PathEntitySelectionModalProps = PathEntitySelectionContextProviderProps & PathEntitySelectionModalInternalProps;

/**
 * Base component for a dialog providing the possibility to select a project, a path, as well as a commit therein. Any
 * additionally given props will be handed through to the internal "SaveCancelModal"
 *
 * There are already components for basically all possible permutation of this, see:
 *
 * - PathSelectionModal
 * - ProjectAndPathSelectionModal
 * - ProjectSelectionModal
 * - GlobalPathSelectionModal
 */
export function PathEntitySelectionModalContent({
	initialPath,
	initialProject,
	initialCommit,
	forbiddenUniformPathTypes = [],
	projectsSelectable,
	disableDirNavigation,
	loadEntries,
	...props
}: PathEntitySelectionModalProps): JSX.Element {
	return (
		<PathEntitySelectionContextProvider
			initialProject={initialProject}
			initialPath={initialPath}
			initialCommit={initialCommit}
			forbiddenUniformPathTypes={forbiddenUniformPathTypes}
			projectsSelectable={projectsSelectable}
			disableDirNavigation={disableDirNavigation}
			loadEntries={loadEntries}
		>
			<PathEntitySelectionModalContentInternal forbiddenUniformPathTypes={forbiddenUniformPathTypes} {...props} />
		</PathEntitySelectionContextProvider>
	);
}

/** Path checker that prohibits architecture paths */
export const DO_NOT_ALLOW_ARCHITECTURE_PATH_TYPES = [EType.ARCHITECTURE];

/** Path checker that prohibits test related paths */
export const DO_NOT_ALLOW_TEST_PATH_TYPES = [EType.TEST_EXECUTION, EType.TEST_IMPLEMENTATION, EType.TEST_QUERY];

/** Path checker that prohibits paths that are not implemented in findings. */
export const DO_NOT_ALLOW_UNIMPLEMENTED_FINDINGS_PATH_TYPES = [
	EType.NON_CODE,
	EType.TEST_IMPLEMENTATION,
	EType.TEST_QUERY,
	EType.ISSUE_ITEM,
	EType.ISSUE_QUERY,
	EType.EXECUTION_UNIT,
	EType.TEST_EXECUTION
];

/** Path checker that prohibits paths that are neither code nor architecture. */
export const IS_CODE_PATH_TYPE = EType.values.filter(type => type !== EType.CODE);

function PathEntitySelectionModalContentInternal({
	disableCommitSelector = false,
	onSave,
	onClose,
	forbiddenUniformPathTypes = []
}: PathEntitySelectionModalInternalProps): JSX.Element {
	const context = usePathEntitySelectionContext();

	return (
		<>
			{!disableCommitSelector && context.validProject ? (
				<SuspendingErrorBoundary>
					<CommitSelector
						initialCommit={context.selectedCommit}
						onChange={context.setCommit}
						currentProject={context.selectedProject}
					/>
				</SuspendingErrorBoundary>
			) : null}

			<PathSelectionInput />
			<br />
			<FileList forbiddenUniformPathTypes={forbiddenUniformPathTypes} />
			<ModalActionButtons>
				<Button
					primary
					content="OK"
					className="!w-40"
					data-testid="path-selection-modal-ok"
					disabled={context.containsError || (context.projectsSelectable && !context.validProject)}
					onClick={() => {
						onSave(context.selectedProject, context.selectedPath, context.selectedCommit);
						onClose();
					}}
				/>
				<Button content="Cancel" onClick={onClose} className="!w-40" />
			</ModalActionButtons>
		</>
	);
}

/**
 * Input element displaying the currently selected path. Also provides the possibility to manually change the path, as
 * well as validation and displaying of any errors.
 */
function PathSelectionInput(): JSX.Element {
	const context = usePathEntitySelectionContext();

	let pathString = context.livePath.toString();
	if (context.projectsSelectable) {
		if (StringUtils.isEmptyOrWhitespace(context.selectedProject)) {
			pathString = '';
		} else if (!context.validProject && context.selectedPath.isEmpty()) {
			pathString = context.selectedProject;
		} else {
			pathString = context.selectedProject + '/' + StringUtils.stripPrefix(context.livePath.toString(), '/');
		}
	}

	let label = 'Path';
	if (context.projectsSelectable && !context.validProject) {
		label = 'Project';
	}

	const invalidPath = !context.isAllowed(pathString);

	return (
		<Input
			className="selected-path"
			type="text"
			label={label}
			size="small"
			value={pathString}
			fluid
			error={context.containsError || invalidPath}
			onChange={e => {
				if (context.projectsSelectable) {
					const projectAndPath = ProjectAndUniformPath.parse(e.target.value);
					context.setProject(projectAndPath.getProject());
					context.setPathDebounced(projectAndPath.getUniformPath());
				} else {
					context.setPathDebounced(new UniformPath(e.target.value));
				}
			}}
		/>
	);
}

/** Type as a wrapper for all necessary information for an entry in the file list */
export type FileListEntry = {
	name: string;
	project?: string;
	path: UniformPath;
	isContainer: boolean;
	icon: JSX.Element;
};

/** Selectable list of all possible entries for the currently selected path/project. */
function FileList({ forbiddenUniformPathTypes }: FileListProps): JSX.Element {
	const context = usePathEntitySelectionContext();
	const projectInfos = useProjectInfos();

	const entries = useQuery({
		queryKey: [
			'entries',
			context.selectedProject,
			context.selectedPath,
			context.selectedCommit,
			projectInfos.projects
		],
		queryFn: () =>
			context.loadEntries(
				context.selectedProject!,
				context.selectedPath,
				context.selectedCommit,
				projectInfos.projects
			),
		throwOnError: false,
		placeholderData: keepPreviousData
	});

	const setError = context.setError;
	useEffect(() => {
		setError(entries.isError);
	}, [setError, entries.isError]);

	const renderEntries = useTextCompletionForEntries(context, entries);

	const isFile = ((renderEntries.data ?? []).length === 0 && !context.containsError) || false;

	let currentPath = context.selectedPath.getPath();
	if (!StringUtils.isEmptyOrWhitespace(context.selectedProject)) {
		currentPath = context.selectedProject + '/' + currentPath;
	}
	if (renderEntries.isPending) {
		return <PlaceholderList numberOfLines={5} />;
	}

	return (
		<div className="file-list-container" data-path={currentPath}>
			<List selection>
				{!context.disableDirNavigation &&
				!isRoot(context.selectedProject, context.selectedPath, context.projectsSelectable) ? (
					<ListItem
						className={styles.listEntry}
						onClick={() => {
							if (context.selectedPath.isProjectRoot()) {
								context.setProject('');
							} else {
								context.setPath(getParent(context.selectedPath));
							}
						}}
						icon={<Icon name="folder" />}
						content=".."
					/>
				) : null}
				{(renderEntries.data ?? [])
					.filter(entry => !entry.path.isAnyOfTypes(forbiddenUniformPathTypes))
					.map(entry => (
						<ListItem
							className={styles.listEntry}
							key={entry.project + '/' + entry.path.getPath()}
							onClick={() => {
								context.setPath(entry.path);
								context.setProject(entry.project);
							}}
							icon={entry.icon}
							content={entry.name}
						/>
					))}
			</List>
			{isFile ? (
				<Message>
					<Icon name="file" />
					The current path is a file
				</Message>
			) : null}
		</div>
	);
}

/**
 * Fetches the entries for the parent path of the currently selected project and path and filters the resulting entries
 * by prefix matching them with the current path or project if the path is empty.
 *
 * Enables the path input to be used as a search bar for selecting a project or a path.
 *
 * Any queries for this function are only done in case the currently selected project or path is invalid, which would be
 * the case if you manually type in half a path, for example.
 */
function useTextCompletionForEntries(
	context: ExposedPathEntitySelectionContext,
	entries: UseQueryResult<FileListEntry[]>
): UseQueryResult<FileListEntry[]> {
	const containsError = entries.isError;
	const projectHasChanged = context.selectedPath.isProjectRoot();
	const projectInfos = useProjectInfos();

	let parentProject = context.selectedProject || '';
	if (projectHasChanged) {
		parentProject = '';
	}

	const parentEntries = useQuery({
		queryKey: [
			'suggestion-entries',
			parentProject,
			getParent(context.selectedPath),
			context.selectedCommit,
			projectInfos.projects
		],
		queryFn: () =>
			context.loadEntries(
				parentProject,
				getParent(context.selectedPath),
				context.selectedCommit,
				projectInfos.projects
			),
		enabled: containsError,
		throwOnError: false
	});

	if (containsError && !parentEntries.isError) {
		return {
			...parentEntries,
			data: (parentEntries.data ?? []).filter(entry => {
				if (projectHasChanged) {
					return entry.project?.startsWith(context.selectedProject || '');
				} else {
					return entry.path.getBasename().startsWith(context.selectedPath.getBasename());
				}
			})
		} as UseQueryResult<FileListEntry[]>;
	}

	return entries;
}

/** Gets the parent of the given path or returns path if it already is the root path. */
function getParent(path: UniformPath): UniformPath {
	if (path.isProjectRoot()) {
		return path;
	}
	return path.getParentPath();
}

/**
 * Checks if the given path is the root path. If the path "/", but projects are selectable and a project is given then
 * it is not a root path
 */
function isRoot(project: string | undefined, path: UniformPath, projectsSelectable: boolean | undefined): boolean {
	if (!projectsSelectable) {
		return path.isProjectRoot();
	}
	return StringUtils.isEmptyOrWhitespace(project) && path.isProjectRoot();
}
