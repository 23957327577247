import { type JSX, useCallback, useLayoutEffect, useRef } from 'react';
import { PerspectiveLoadingIndicator } from 'ts/base/components/PerspectiveLoadingIndicator';
import { useAsync } from 'ts/base/hooks/AsyncHook';
import { DisposableKeyboardShortcutRegistry } from 'ts/base/scaffolding/DisposableKeyboardShortcutRegistry';
import { TeamscaleView } from 'ts/base/TeamscaleView';
import { useTeamscaleViewContext } from 'ts/base/TeamscaleViewContext';
import type { TeamscaleViewBase } from 'ts/base/view/TeamscaleViewBase';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { tsdom } from 'ts/commons/tsdom';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/** Props for TeamscalePerspective. */
type TeamscaleViewWrapperProps = {
	view: TeamscaleViewBase;
	hash: NavigationHash;
	context: ExtendedPerspectiveContext;
};

/** Wraps a TeamscalePerspectiveBase into React. It asynchronously loads the data of the view and renders it when done. */
export function TeamscaleViewWrapper({ view, hash, context }: TeamscaleViewWrapperProps): JSX.Element {
	const { defaultBranchName } = useTeamscaleViewContext();
	const mainContentLoaded = useRef<boolean>(false);

	const mainContainerRef = useRef<HTMLDivElement | null>(null);
	const rightSidebarContainerRef = useRef<HTMLDivElement | null>(null);
	const viewContentRef = useRef<HTMLDivElement | null>(null);

	const initAndPreloader = useCallback(() => {
		view.init(context, defaultBranchName, new DisposableKeyboardShortcutRegistry());
		return view.preloadContentAsync();
	}, [context, defaultBranchName, view]);
	const preloadResult = useAsync(initAndPreloader);

	useLayoutEffect(() => {
		if (preloadResult.status === 'success') {
			if (view.disposed) {
				// Happens when React tries to perform a hot reload of a component
				location.reload();
			}
			mainContentLoaded.current = true;
			const mainContainer = mainContainerRef.current!;
			const viewContent = viewContentRef.current!;
			const rightSidebarContainer = rightSidebarContainerRef.current!;
			view.renderInto(viewContent, rightSidebarContainer, hash);
			// Focus the main container if no other element is focused to enable keyboard scrolling
			if (!document.hasFocus()) {
				mainContainer.focus();
			}
			return () => {
				mainContainer.style.display = '';
				view.dispose();
				tsdom.removeAllChildren(mainContainer);
				mainContainer.style.display = 'none';
			};
		}
		return;
	}, [hash, context, view, preloadResult.status]);
	return (
		<TeamscaleView
			ref={mainContainerRef}
			rightSidebar={<div ref={rightSidebarContainerRef} style={{ display: 'contents' }} />}
		>
			<div ref={viewContentRef} style={{ display: 'contents' }} />
			{preloadResult.status === 'pending' ? <PerspectiveLoadingIndicator /> : null}
		</TeamscaleView>
	);
}
