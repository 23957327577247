import clsx from 'clsx';
import { type ElementType, type ReactNode, type RefObject } from 'react';
import { childrenUtils, getComponentType, getUnhandledProps, keyOnly } from 'ts/components/lib';
import type { SemanticShorthandContent } from '../Generic';

/** Props for {@link Divider}. */
export type DividerProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Divider can clear the content above it. */
	clearing?: boolean;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** Divider can be fitted without any space above or below it. */
	fitted?: boolean;

	/** Divider can divide content without creating a dividing line. */
	hidden?: boolean;

	/** Divider can segment content horizontally. */
	horizontal?: boolean;

	/** Divider can have its colours inverted. */
	inverted?: boolean;

	/** Divider can provide greater margins to divide sections of content. */
	section?: boolean;

	/** Divider can segment content vertically. */
	vertical?: boolean;
};

/** A divider visually segments content into groups. */
export function Divider(props: DividerProps) {
	const { children, className, clearing, content, fitted, hidden, horizontal, inverted, section, vertical } = props;

	const classes = clsx(
		'ui',
		keyOnly(clearing, 'clearing'),
		keyOnly(fitted, 'fitted'),
		keyOnly(hidden, 'hidden'),
		keyOnly(horizontal, 'horizontal'),
		keyOnly(inverted, 'inverted'),
		keyOnly(section, 'section'),
		keyOnly(vertical, 'vertical'),
		'divider',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}
const handledProps = [
	'as',
	'children',
	'className',
	'clearing',
	'content',
	'fitted',
	'hidden',
	'horizontal',
	'inverted',
	'section',
	'vertical'
];
