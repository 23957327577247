import clsx from 'clsx';
import { type ElementType, Fragment, type Key, type MouseEvent, type ReactNode } from 'react';
import {
	type DataAttributes,
	type HtmlSpanProps,
	type PolymorphicComponentPropWithRef,
	type SemanticShorthandContent,
	type SemanticShorthandItem
} from '../Generic';
import type { IconProps } from '../Icon';
import { createIcon } from '../Icon/Icon';
import { createImage, type ImageProps } from '../Image/Image';
import { createLabel, type LabelProps } from '../Label/Label';
import {
	childrenUtils,
	createShorthand,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly
} from '../lib';

/** Props for {@link BasicDropdownItem}. */
export type BasicDropdownItemProps = {
	/** Style as the currently chosen item. */
	active?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** Additional text with less emphasis. */
	description?: SemanticShorthandItem<HtmlSpanProps>;

	/** A dropdown item can be disabled. */
	disabled?: boolean;

	/** Shorthand for Icon. */
	icon?: SemanticShorthandItem<IconProps>;

	/** Shorthand for Image. */
	image?: SemanticShorthandItem<ImageProps>;

	/** Shorthand for Label. */
	label?: SemanticShorthandItem<LabelProps>;

	/** Called on click. */
	onClick?: (event: MouseEvent, data: BasicDropdownItemProps) => void;

	/** The item currently selected by keyboard shortcut. This is not the active item. */
	selected?: boolean;

	/** Display text. */
	text?: SemanticShorthandContent;

	/** Stored value. */
	value?: string;

	key?: Key;

	/** The href attribute is used when "as" is set to "a". */
	href?: string;
	/** The target attribute is used when "as" is set to "a". */
	target?: '_blank';
	/** The rel attribute is used when "as" is set to "a". */
	rel?: 'noopener noreferrer';

	/** The "to" attribute used when "as" is set to "TeamscaleLink". */
	to?: string;
} & DataAttributes;

type GenericDropdownItemProps<C extends ElementType> = PolymorphicComponentPropWithRef<C, BasicDropdownItemProps>;
export type DropdownItemProps<C extends ElementType = ElementType> = GenericDropdownItemProps<C>;

/** An item sub-component for Dropdown component. */
export function DropdownItem<C extends ElementType = 'div'>(props: GenericDropdownItemProps<C>) {
	const { active, children, className, content, disabled, description, icon, image, label, selected, text } = props;

	const handleClick = (e: MouseEvent) => {
		props.onClick?.(e, props);
	};

	const classes = clsx(
		keyOnly(active, 'active'),
		keyOnly(disabled, 'disabled'),
		keyOnly(selected, 'selected'),
		'item',
		className
	);
	// add default dropdown icon if item contains another menu
	const iconName = icon == null ? childrenUtils.someByType(children, 'DropdownMenu') && 'dropdown' : icon;
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);
	const ariaOptions = {
		role: 'option',
		'aria-disabled': disabled,
		'aria-checked': active,
		'aria-selected': selected
	};

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} {...ariaOptions} className={classes} onClick={handleClick}>
				{children}
			</ElementType>
		);
	}

	const iconElement = createIcon(iconName, { autoGenerateKey: false });
	const imageElement = createImage(image, { autoGenerateKey: false });
	const labelElement = createLabel(label, { autoGenerateKey: false });
	const descriptionElement = createShorthand('span', val => ({ children: val }), description, {
		defaultProps: { className: 'description' },
		autoGenerateKey: false
	});
	const textElement = createShorthand(
		'span',
		val => ({ children: val }),
		childrenUtils.isNil(content) ? text : content,
		{
			defaultProps:
				content != null && typeof content === 'object' && 'type' in content && content.type === Fragment
					? {}
					: { className: 'text' },
			autoGenerateKey: false
		}
	);

	return (
		<ElementType {...rest} {...ariaOptions} className={classes} onClick={handleClick}>
			{imageElement}
			{iconElement}
			{labelElement}
			{descriptionElement}
			{textElement}
		</ElementType>
	);
}

export const createDropdownItem = createShorthandFactory(DropdownItem);
const handledProps = [
	'active',
	'as',
	'children',
	'className',
	'content',
	'description',
	'disabled',
	'flag',
	'icon',
	'image',
	'label',
	'onClick',
	'selected',
	'text',
	'value'
];
