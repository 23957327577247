import clsx from 'clsx';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	type MouseEvent,
	type ReactNode,
	type RefObject
} from 'react';
import type { SemanticCOLORS, SemanticShorthandCollection, SemanticWIDTHS } from '../Generic';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useAutoControlledValue,
	valueAndKey,
	widthProp
} from '../lib';
import { type BasicMenuItemProps, createMenuItem, type MenuItemProps } from './MenuItem';

/** Props for {@link Menu}. */
export type MenuProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Index of the currently active item. */
	activeIndex?: number;

	/** A menu may be attached to other content segments. */
	attached?: boolean | 'bottom' | 'top';

	/** A menu item or menu can have no borders. */
	borderless?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Additional colors can be specified. */
	color?: SemanticCOLORS;

	/** A menu can take up only the space necessary to fit its content. */
	compact?: boolean;

	/** Initial activeIndex value. */
	defaultActiveIndex?: number;

	/** A menu can be fixed to a side of its context. */
	fixed?: 'left' | 'right' | 'bottom' | 'top';

	/** A menu can be floated. */
	floated?: boolean | 'right';

	/** A vertical menu may take the size of its container. */
	fluid?: boolean;

	/** A menu may have labeled icons. */
	icon?: boolean | 'labeled';

	/** A menu may have its colors inverted to show greater contrast. */
	inverted?: boolean;

	/** Shorthand array of props for Menu. */
	items?: SemanticShorthandCollection<MenuItemProps>;

	/** OnClick handler for MenuItem. Mutually exclusive with children. */
	onItemClick?: (event: MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => void;

	/** A pagination menu is specially formatted to present links to pages of content. */
	pagination?: boolean;

	/** A menu can point to show its relationship to nearby content. */
	pointing?: boolean;

	/** A menu can adjust its appearance to de-emphasize its contents. */
	secondary?: boolean;

	/** A menu can vary in size. */
	size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge' | 'massive';

	/** A menu can stack at mobile resolutions. */
	stackable?: boolean;

	/** A menu can be formatted to show tabs of information. */
	tabular?: boolean | 'right';

	/** A menu can be formatted for text content. */
	text?: boolean;

	/** A vertical menu displays elements vertically. */
	vertical?: boolean;

	/** A menu can have its items divided evenly. */
	widths?: SemanticWIDTHS;
};

/**
 * A menu displays grouped navigation actions.
 *
 * @see Dropdown
 */
export function Menu(props: MenuProps) {
	const {
		attached,
		borderless,
		children,
		className,
		color,
		compact,
		fixed,
		floated,
		fluid,
		icon,
		inverted,
		items,
		pagination,
		pointing,
		secondary,
		size,
		stackable,
		tabular,
		text,
		vertical,
		widths
	} = props;
	const [activeIndex, setActiveIndex] = useAutoControlledValue({
		state: props.activeIndex,
		defaultState: props.defaultActiveIndex,
		initialState: -1
	});

	const classes = clsx(
		'ui',
		color,
		size,
		keyOnly(borderless, 'borderless'),
		keyOnly(compact, 'compact'),
		keyOnly(fluid, 'fluid'),
		keyOnly(inverted, 'inverted'),
		keyOnly(pagination, 'pagination'),
		keyOnly(pointing, 'pointing'),
		keyOnly(secondary, 'secondary'),
		keyOnly(stackable, 'stackable'),
		keyOnly(text, 'text'),
		keyOnly(vertical, 'vertical'),
		keyOrValueAndKey(attached, 'attached'),
		keyOrValueAndKey(floated, 'floated'),
		keyOrValueAndKey(icon, 'icon'),
		keyOrValueAndKey(tabular, 'tabular'),
		valueAndKey(fixed, 'fixed'),
		widthProp(widths, 'item'),
		className,
		'menu'
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes}>
				{children}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes}>
			{items?.map((item, index) =>
				createMenuItem(item, {
					defaultProps: {
						active: activeIndex === index,
						index
					},
					overrideProps: (predefinedProps: BasicMenuItemProps) => ({
						onClick: (e: MouseEvent<HTMLAnchorElement>, itemProps: BasicMenuItemProps) => {
							const itemIndex = itemProps.index;

							setActiveIndex(itemIndex);

							predefinedProps.onClick?.(e, itemProps);
							props.onItemClick?.(e, itemProps);
						}
					})
				})
			)}
		</ElementType>
	);
}

export const createMenu = createShorthandFactory<typeof Menu, SemanticShorthandCollection<MenuItemProps>>(
	Menu,
	items => ({
		items
	})
);
const handledProps = [
	'activeIndex',
	'as',
	'attached',
	'borderless',
	'children',
	'className',
	'color',
	'compact',
	'defaultActiveIndex',
	'fixed',
	'floated',
	'fluid',
	'icon',
	'inverted',
	'items',
	'onItemClick',
	'pagination',
	'pointing',
	'secondary',
	'size',
	'stackable',
	'tabular',
	'text',
	'vertical',
	'widths'
];
