import type { VirtualElement } from '@popperjs/core/lib/types';
import { memoize } from 'es-toolkit';
import type { RefObject } from 'react';
import { isRefObject } from '../../lib';

/**
 * Popper.js does not support ref objects from `createRef()` as referenceElement. If we will pass directly `ref`,
 * `ref.current` will be `null` at the render process. We use memoize to keep the same reference between renders.
 *
 * @see https://popper.js.org/docs/v2/virtual-elements/
 */
export const createVirtualElement: (ref: RefObject<HTMLElement | null> | HTMLElement) => VirtualElement = memoize(
	reference => {
		const refObject = isRefObject(reference)
			? (reference as RefObject<HTMLElement | null>)
			: { current: reference as HTMLElement };
		return {
			getBoundingClientRect(): DOMRect {
				return refObject.current?.getBoundingClientRect() || new DOMRect();
			},
			get contextElement() {
				return refObject.current instanceof HTMLElement ? refObject.current : undefined;
			}
		};
	}
);
