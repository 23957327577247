import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ReactNode, type RefObject } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent, SemanticShorthandItem } from '../Generic';
import { type IconProps } from '../Icon';
import { createIcon } from '../Icon/Icon';
import { childrenUtils, createShorthandFactory, getComponentType, getUnhandledProps } from '../lib';

/** Props for {@link DropdownHeader}. */
export type DropdownHeaderProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** Shorthand for Icon. */
		icon?: SemanticShorthandItem<IconProps>;
	}
>;

/** A dropdown menu can contain a header. */
export function DropdownHeader(props: DropdownHeaderProps) {
	const { children, className, content, icon } = props;

	const classes = clsx('header', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...rest} className={classes}>
				{children}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes}>
			{createIcon(icon, { autoGenerateKey: false })}
			{content}
		</ElementType>
	);
}

export const createDropdownHeader = createShorthandFactory(DropdownHeader, content => ({ content }));
const handledProps = ['as', 'children', 'className', 'content', 'icon'];
