import clsx from 'clsx';
import { type ElementType, type MouseEvent, type ReactNode, useRef } from 'react';
import {
	type DataAttributes,
	type IconNameOrElement,
	type PolymorphicComponentPropWithRef,
	type SemanticCOLORS,
	type SemanticFLOATS,
	type SemanticShorthandContent,
	type SemanticShorthandItem,
	type SemanticSIZES
} from '../Generic';
import { createIcon } from '../Icon/Icon';
import { type LabelProps } from '../Label';
import { createLabel } from '../Label/Label';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	useMergedRefs,
	valueAndKey
} from '../lib';

type BasicButtonProps = {
	/** A button can show it is currently the active user selection. */
	active?: boolean;

	/** A button can animate to show hidden content. */
	animated?: boolean | 'fade' | 'vertical';

	/** A button can be attached to other content. */
	attached?: boolean | 'left' | 'right' | 'top' | 'bottom';

	/** A basic button is less pronounced. */
	basic?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** A button can be circular. */
	circular?: boolean;

	/** Additional classes. */
	className?: string;

	/** A button can have different colors. */
	color?: SemanticCOLORS | 'facebook' | 'google plus' | 'vk' | 'twitter' | 'linkedin' | 'instagram' | 'youtube';

	/** A button can reduce its padding to fit into tighter spaces. */
	compact?: boolean;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A button can show it is currently unable to be interacted with. */
	disabled?: boolean;

	/** A button can be aligned to the left or right of its container. */
	floated?: SemanticFLOATS;

	/** A button can take the width of its container. */
	fluid?: boolean;

	/** Add an Icon by name, or pass an <Icon />. */
	icon?: boolean | IconNameOrElement;

	/** A button can be formatted to appear on dark backgrounds. */
	inverted?: boolean;

	/** Add a Label by text, props object, or pass a <Label />. */
	label?: SemanticShorthandItem<LabelProps>;

	/** A labeled button can format a Label or Icon to appear on the left or right. */
	labelPosition?: 'right' | 'left';

	/** A button can show a loading indicator. */
	loading?: boolean;

	/** A button can hint towards a negative consequence. */
	negative?: boolean;

	/** A button can hint towards a positive consequence. */
	positive?: boolean;

	/** A button can be formatted to show different levels of emphasis. */
	primary?: boolean;

	/** The role of the HTML element. */
	role?: string;

	/** A button can be formatted to show different levels of emphasis. */
	secondary?: boolean;

	/** A button can have different sizes. */
	size?: SemanticSIZES;

	/** A button can receive focus. */
	tabIndex?: number;

	/** A button can be formatted to toggle on and off. */
	toggle?: boolean;

	/** The type of the HTML element. */
	type?: 'submit' | 'reset' | 'button';
} & DataAttributes;

type GenericButtonProps<C extends ElementType> = PolymorphicComponentPropWithRef<C, BasicButtonProps>;
export type ButtonProps<C extends ElementType = 'button'> = GenericButtonProps<C>;

function computeButtonAriaRole(ElementType: ElementType, role: string | undefined) {
	if (role != null) {
		return role;
	}

	if (ElementType !== 'button') {
		return 'button';
	}
	return undefined;
}

function computeTabIndex(ElementType: ElementType, disabled: boolean | undefined, tabIndex: number | undefined) {
	if (tabIndex != null) {
		return tabIndex;
	}
	if (disabled) {
		return -1;
	}
	if (ElementType === 'div') {
		return 0;
	}
	return undefined;
}

function hasIconClass(props: ButtonProps<ElementType>) {
	const { children, content, icon, labelPosition } = props;

	if (icon === true) {
		return true;
	}

	if (icon) {
		return labelPosition || (childrenUtils.isNil(children) && content == null);
	}
	return undefined;
}

/**
 * A button allows a user to perform an action, with mouse, touch, and keyboard interactions.
 *
 * ```tsx
 * import { Button } from 'ts/components/Button';
 * ```
 */
export function Button<C extends ElementType = 'button'>(props: GenericButtonProps<C>) {
	const {
		active,
		animated,
		attached,
		basic,
		children,
		circular,
		className,
		color,
		compact,
		content,
		disabled,
		floated,
		fluid,
		icon,
		inverted,
		label,
		labelPosition,
		loading,
		negative,
		positive,
		primary,
		secondary,
		size,
		toggle,
		type,
		ref
	} = props;
	const elementRef = useMergedRefs(ref, useRef(null));

	const baseClasses = clsx(
		color,
		size,
		keyOnly(active, 'active'),
		keyOnly(basic, 'basic'),
		keyOnly(circular, 'circular'),
		keyOnly(compact, 'compact'),
		keyOnly(fluid, 'fluid'),
		keyOnly(hasIconClass(props), 'icon'),
		keyOnly(inverted, 'inverted'),
		keyOnly(loading, 'loading'),
		keyOnly(negative, 'negative'),
		keyOnly(positive, 'positive'),
		keyOnly(primary, 'primary'),
		keyOnly(secondary, 'secondary'),
		keyOnly(toggle, 'toggle'),
		keyOrValueAndKey(animated, 'animated'),
		keyOrValueAndKey(attached, 'attached')
	);
	const labeledClasses = clsx(keyOrValueAndKey(labelPosition || Boolean(label), 'labeled'));
	const wrapperClasses = clsx(keyOnly(disabled, 'disabled'), valueAndKey(floated, 'floated'));

	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props, {
		defaultAs: 'button',
		getDefault: () => {
			if (attached != null || label != null) {
				return 'div';
			}
			return undefined;
		}
	});
	const tabIndex = computeTabIndex(ElementType, disabled, props.tabIndex);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (disabled) {
			e.preventDefault();
			return;
		}

		props.onClick?.(e, props);
	};

	if (label != null) {
		const buttonClasses = clsx('ui', baseClasses, 'button', className);
		const containerClasses = clsx('ui', labeledClasses, 'button', className, wrapperClasses);
		const labelElement = createLabel(label, {
			defaultProps: {
				basic: true,
				pointing: labelPosition === 'left' ? 'right' : 'left'
			},
			autoGenerateKey: false
		});

		return (
			<ElementType {...rest} className={containerClasses} onClick={handleClick}>
				{labelPosition === 'left' && labelElement}
				<button
					className={buttonClasses}
					aria-pressed={toggle ? Boolean(active) : undefined}
					disabled={disabled}
					tabIndex={tabIndex}
					type={type}
					ref={elementRef}
				>
					{createIcon(icon, { autoGenerateKey: false })} {content}
				</button>
				{(labelPosition === 'right' || !labelPosition) && labelElement}
			</ElementType>
		);
	}

	const classes = clsx('ui', baseClasses, wrapperClasses, labeledClasses, 'button', className);
	const hasChildren = !childrenUtils.isNil(children);
	const role = computeButtonAriaRole(ElementType, props.role);

	return (
		<ElementType
			{...rest}
			className={classes}
			aria-pressed={toggle ? Boolean(active) : undefined}
			disabled={(disabled && ElementType === 'button') || undefined}
			onClick={handleClick}
			role={role}
			tabIndex={tabIndex}
			type={type}
			ref={elementRef}
		>
			{hasChildren ? children : null}
			{!hasChildren && createIcon(icon, { autoGenerateKey: false })}
			{!hasChildren && content}
		</ElementType>
	);
}

export const createButton = createShorthandFactory(Button, value => ({ content: value }));
const handledProps = [
	'active',
	'animated',
	'as',
	'attached',
	'basic',
	'children',
	'circular',
	'className',
	'color',
	'compact',
	'content',
	'disabled',
	'floated',
	'fluid',
	'icon',
	'inverted',
	'label',
	'labelPosition',
	'loading',
	'negative',
	'onClick',
	'positive',
	'primary',
	'role',
	'secondary',
	'size',
	'tabIndex',
	'toggle',
	'type'
];
