import { QUERY, useQueryOperation } from 'api/Query';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import type { JSX } from 'react';

/** Warns the user when there is no valid license or the license is expired/about to expire. */
export function useLegacyWebHookWarning(): JSX.Element | null {
	const { data: showLegacyWebhookFlagWarning } = useQueryOperation(QUERY.showLegacyWebHookWarning());
	if (showLegacyWebhookFlagWarning) {
		return (
			<GlobalWarning
				message="Legacy JVM Flag enabled."
				tooltip="You have the legacy JVM flag 'disable-automatic-webhook-creation' enabled. Please change it to 'disable-automatic-webhook-creation-default'."
				severity="warning"
			/>
		);
	} else {
		return null;
	}
}
