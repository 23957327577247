import clsx from 'clsx';
import { type ElementType, type ReactNode, type RefObject } from 'react';
import type {
	SemanticCOLORS,
	SemanticFLOATS,
	SemanticShorthandCollection,
	SemanticShorthandContent,
	SemanticSIZES,
	SemanticWIDTHS
} from '../Generic';
import {
	childrenUtils,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	keyOrValueAndKey,
	valueAndKey,
	widthProp
} from '../lib';
import { type ButtonProps, createButton } from './Button';

/** Props for {@link ButtonGroup}. */
export type ButtonGroupProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Groups can be attached to other content. */
	attached?: boolean | 'left' | 'right' | 'top' | 'bottom';

	/** Groups can be less pronounced. */
	basic?: boolean;

	/** Array of shorthand Button values. */
	buttons?: SemanticShorthandCollection<ButtonProps>;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Groups can have a shared color. */
	color?: SemanticCOLORS;

	/** Groups can reduce their padding to fit into tighter spaces. */
	compact?: boolean;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** Groups can be aligned to the left or right of its container. */
	floated?: SemanticFLOATS;

	/** Groups can take the width of their container. */
	fluid?: boolean;

	/** Groups can be formatted as icons. */
	icon?: boolean;

	/** Groups can be formatted to appear on dark backgrounds. */
	inverted?: boolean;

	/** Groups can be formatted as labeled icon buttons. */
	labeled?: boolean;

	/** Groups can hint towards a negative consequence. */
	negative?: boolean;

	/** Groups can hint towards a positive consequence. */
	positive?: boolean;

	/** Groups can be formatted to show different levels of emphasis. */
	primary?: boolean;

	/** Groups can be formatted to show different levels of emphasis. */
	secondary?: boolean;

	/** Groups can have different sizes. */
	size?: SemanticSIZES;

	/** Groups can be formatted to toggle on and off. */
	toggle?: boolean;

	/** Groups can be formatted to appear vertically. */
	vertical?: boolean;

	/** Groups can have their widths divided evenly. */
	widths?: SemanticWIDTHS;
};
/**
 * Groups multiple buttons together to appear as one logical element with each child button being a segment of the whole
 * control.
 *
 * ```tsx
 * import { ButtonGroup } from 'ts/components/Button';
 * ```
 */
export function ButtonGroup(props: ButtonGroupProps) {
	const {
		attached,
		basic,
		buttons,
		children,
		className,
		color,
		compact,
		content,
		floated,
		fluid,
		icon,
		inverted,
		labeled,
		negative,
		positive,
		primary,
		secondary,
		size,
		toggle,
		vertical,
		widths
	} = props;

	const classes = clsx(
		'ui',
		color,
		size,
		keyOnly(basic, 'basic'),
		keyOnly(compact, 'compact'),
		keyOnly(fluid, 'fluid'),
		keyOnly(icon, 'icon'),
		keyOnly(inverted, 'inverted'),
		keyOnly(labeled, 'labeled'),
		keyOnly(negative, 'negative'),
		keyOnly(positive, 'positive'),
		keyOnly(primary, 'primary'),
		keyOnly(secondary, 'secondary'),
		keyOnly(toggle, 'toggle'),
		keyOnly(vertical, 'vertical'),
		keyOrValueAndKey(attached, 'attached'),
		valueAndKey(floated, 'floated'),
		widthProp(widths),
		'buttons',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	if (buttons == null) {
		return (
			<ElementType {...rest} className={classes}>
				{childrenUtils.isNil(children) ? content : children}
			</ElementType>
		);
	}

	return (
		<ElementType {...rest} className={classes}>
			{buttons.map(button => createButton(button))}
		</ElementType>
	);
}
const handledProps = [
	'as',
	'attached',
	'basic',
	'buttons',
	'children',
	'className',
	'color',
	'compact',
	'content',
	'floated',
	'fluid',
	'icon',
	'inverted',
	'labeled',
	'negative',
	'positive',
	'primary',
	'secondary',
	'size',
	'toggle',
	'vertical',
	'widths'
];
