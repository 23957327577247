/** Generated from EAvatarSize.java. Do not modify this file! */


export type EAvatarSizeEntry = 'ORIGINAL' | 'TINY' | 'SMALL' | 'MEDIUM' | 'LARGE';

export class EAvatarSize {
	static readonly ORIGINAL = new EAvatarSize(0, 'ORIGINAL', -1);
	static readonly TINY = new EAvatarSize(1, 'TINY', 50);
	static readonly SMALL = new EAvatarSize(2, 'SMALL', 100);
	static readonly MEDIUM = new EAvatarSize(3, 'MEDIUM', 200);
	static readonly LARGE = new EAvatarSize(4, 'LARGE', 300);
	static readonly values = [
		EAvatarSize.ORIGINAL,
		EAvatarSize.TINY,
		EAvatarSize.SMALL,
		EAvatarSize.MEDIUM,
		EAvatarSize.LARGE
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EAvatarSizeEntry,
		public readonly size: number
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: EAvatarSizeEntry): EAvatarSize;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: EAvatarSizeEntry | undefined): EAvatarSize | undefined;
	public static valueOf(entry: EAvatarSizeEntry | undefined): EAvatarSize | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EAvatarSize[entry];
	}
}

