import { type ChangeEvent, type ComponentPropsWithoutRef, type FormEvent, type RefObject, useRef } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { getUnhandledProps, useMergedRefs } from '../lib';

/** Props for {@link TextArea}. */
export type TextAreaProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'textarea'>,
	{
		/** Called on change. */
		onChange?: (event: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;

		/** Called on input. */
		onInput?: (event: FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => void;

		/** The value of the textarea. */
		value?: number | string;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLTextAreaElement>;
	}
>;

/**
 * A TextArea can be used to allow for extended user input.
 *
 * @see Form
 */
export function TextArea({ ref, ...props }: TextAreaProps) {
	const { rows = 3, value, onChange, onInput } = props;
	const elementRef = useMergedRefs(ref, useRef(null));

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = e.target.value;

		onChange?.(e, { ...props, value: newValue });
	};

	const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
		const newValue = e.currentTarget.value;

		onInput?.(e, { ...props, value: newValue });
	};

	const rest = getUnhandledProps(handledProps, props);

	return (
		<textarea {...rest} onChange={handleChange} onInput={handleInput} ref={elementRef} rows={rows} value={value} />
	);
}
const handledProps = ['as', 'onChange', 'onInput', 'rows', 'value'];
