import { type JSX } from 'react';
import { useLoaderData } from 'react-router-dom';
import type { TeamscaleViewLoaderData } from 'ts/base/TeamscaleViewRoot.loader';

/**
 * Handles drawing the skeleton of a view including the perspective settings bar. The view itself is also rendered here
 * once loading finished.
 */
export function TeamscaleViewRoot(): JSX.Element | null {
	return useLoaderData() as TeamscaleViewLoaderData;
}
