/** Generated from EType.java. Do not modify this file! */


export type ETypeEntry = 'CODE' | 'NON_CODE' | 'ARCHITECTURE' | 'TEST_IMPLEMENTATION' | 'TEST_EXECUTION' | 'TEST_QUERY' | 'ISSUE_ITEM' | 'ISSUE_QUERY' | 'SPEC_ITEM' | 'SPEC_ITEM_QUERY' | 'EXECUTION_UNIT';

export class EType {
	static readonly CODE = new EType(0, 'CODE', "-code-");
	static readonly NON_CODE = new EType(1, 'NON_CODE', "-non-code-");
	static readonly ARCHITECTURE = new EType(2, 'ARCHITECTURE', "-architectures-");
	static readonly TEST_IMPLEMENTATION = new EType(3, 'TEST_IMPLEMENTATION', "-test-implementation-");
	static readonly TEST_EXECUTION = new EType(4, 'TEST_EXECUTION', "-test-execution-");
	static readonly TEST_QUERY = new EType(5, 'TEST_QUERY', "-test-query-");
	static readonly ISSUE_ITEM = new EType(6, 'ISSUE_ITEM', "-issue-item-");
	static readonly ISSUE_QUERY = new EType(7, 'ISSUE_QUERY', "-issues-");
	static readonly SPEC_ITEM = new EType(8, 'SPEC_ITEM', "-spec-item-");
	static readonly SPEC_ITEM_QUERY = new EType(9, 'SPEC_ITEM_QUERY', "-spec-items-");
	static readonly EXECUTION_UNIT = new EType(10, 'EXECUTION_UNIT', "-execution-unit-");
	static readonly values = [
		EType.CODE,
		EType.NON_CODE,
		EType.ARCHITECTURE,
		EType.TEST_IMPLEMENTATION,
		EType.TEST_EXECUTION,
		EType.TEST_QUERY,
		EType.ISSUE_ITEM,
		EType.ISSUE_QUERY,
		EType.SPEC_ITEM,
		EType.SPEC_ITEM_QUERY,
		EType.EXECUTION_UNIT
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ETypeEntry,
		public readonly prefix: string
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: ETypeEntry): EType;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: ETypeEntry | undefined): EType | undefined;
	public static valueOf(entry: ETypeEntry | undefined): EType | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EType[entry];
	}
}

