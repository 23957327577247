import clsx from 'clsx';
import { type ElementType, type Key, type ReactNode, type RefObject } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps, keyOnly } from '../lib';
import { SearchCategoryLayout, type SearchCategoryLayoutProps } from './SearchCategoryLayout';
import type { SearchResultProps } from './SearchResult';

/** Props for {@link SearchCategory}. */
export type SearchCategoryProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** The item currently selected by keyboard shortcut. */
	active?: boolean;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** Display name. */
	name?: string;

	/**
	 * Renders the SearchCategory layout.
	 *
	 * @param {object} props - The SearchCategoryLayout props object.
	 * @returns {any} - Renderable SearchCategory layout.
	 */
	layoutRenderer?: (props: Pick<SearchCategoryLayoutProps, 'categoryContent' | 'resultsContent'>) => ReactNode;

	/**
	 * Renders the category contents.
	 *
	 * @param {object} props - The SearchCategory props object.
	 * @returns {any} - Renderable category contents.
	 */
	renderer?: (props: SearchCategoryProps) => ReactNode;

	/** Array of Search.Result props. */
	results?: SearchResultProps[];

	childKey?: Key;
};

export function SearchCategory(props: SearchCategoryProps) {
	const {
		active,
		children,
		className,
		content,
		layoutRenderer = SearchCategoryLayout,
		renderer = ({ name }) => name
	} = props;

	const classes = clsx(keyOnly(active, 'active'), 'category', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	const categoryContent = renderer(props);
	const resultsContent = childrenUtils.isNil(children) ? content : children;

	return (
		<ElementType {...rest} className={classes}>
			{layoutRenderer({ categoryContent, resultsContent })}
		</ElementType>
	);
}
const handledProps = [
	'active',
	'as',
	'children',
	'className',
	'content',
	'layoutRenderer',
	'name',
	'renderer',
	'results'
];
