import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ReactNode, type RefObject } from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps, keyOnly } from '../lib';

/** Props for {@link DropdownMenu}. */
export type DropdownMenuProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandContent;

		/** A dropdown menu can open to the left or to the right. */
		direction?: 'left' | 'right';

		/** Whether or not the dropdown menu is displayed. */
		open?: boolean;

		/** A dropdown menu can scroll. */
		scrolling?: boolean;
	}
>;

/** A dropdown menu can contain a menu. */
export function DropdownMenu(props: DropdownMenuProps) {
	const { children, className, content, direction, open, scrolling } = props;

	const classes = clsx(
		direction,
		keyOnly(open, 'visible'),
		keyOnly(scrolling, 'scrolling'),
		'menu transition',
		className
	);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}
const handledProps = ['as', 'children', 'className', 'content', 'direction', 'open', 'scrolling'];
