import clsx from 'clsx';
import * as _ from 'es-toolkit';
import {
	type ComponentPropsWithoutRef,
	type ElementType,
	isValidElement,
	type MouseEvent,
	type ReactNode,
	type RefObject
} from 'react';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import type { SemanticShorthandItem } from '../Generic';
import type { ImageProps } from '../Image';
import { createImage } from '../Image/Image';
import {
	childrenUtils,
	createShorthandFactory,
	getComponentType,
	getUnhandledProps,
	keyOnly,
	useEventCallback
} from '../lib';
import type { ListContentProps } from './ListContent';
import { createListContent, ListContent } from './ListContent';
import type { ListDescriptionProps } from './ListDescription';
import { createListDescription } from './ListDescription';
import type { ListHeaderProps } from './ListHeader';
import { createListHeader } from './ListHeader';
import type { ListIconProps } from './ListIcon';
import { createListIcon } from './ListIcon';

/** Props for {@link ListItem}. */
export type ListItemProps = ExtendTypeWith<
	ComponentPropsWithoutRef<'div'>,
	{
		/** An element type to render as (string or function). */
		as?: ElementType;

		/** The ref allows retrieving a reference to the underlying DOM node. */
		ref?: RefObject<HTMLDivElement>;

		/** A list item can active. */
		active?: boolean;

		/** Primary content. */
		children?: ReactNode;

		/** Additional classes. */
		className?: string;

		/** Shorthand for primary content. */
		content?: SemanticShorthandItem<ListContentProps>;

		/** Shorthand for ListDescription. */
		description?: SemanticShorthandItem<ListDescriptionProps>;

		/** A list item can disabled. */
		disabled?: boolean;

		/** Shorthand for ListHeader. */
		header?: SemanticShorthandItem<ListHeaderProps>;

		/** Shorthand for ListIcon. */
		icon?: SemanticShorthandItem<ListIconProps>;

		/** Shorthand for Image. */
		image?: SemanticShorthandItem<ImageProps>;

		/** Called on click. */
		onClick?: (event: MouseEvent<HTMLAnchorElement>, data: ListItemProps) => void;

		/** A value for an ordered list. */
		value?: string;
	}
>;

/** A list item can contain a set of items. */
export function ListItem(props: ListItemProps) {
	const { active, children, className, content, description, disabled, header, icon, image, value } = props;

	const ElementType = getComponentType(props);
	const classes = clsx(
		keyOnly(active, 'active'),
		keyOnly(disabled, 'disabled'),
		keyOnly(ElementType !== 'li', 'item'),
		className
	);
	const rest = getUnhandledProps(handledProps, props);

	const handleClick = useEventCallback((e: MouseEvent<HTMLAnchorElement>) => {
		if (!disabled) {
			props.onClick?.(e, props);
		}
	});
	const valueProp = ElementType === 'li' ? { value } : { 'data-value': value };

	if (!childrenUtils.isNil(children)) {
		return (
			<ElementType {...valueProp} role="listitem" {...rest} className={classes} onClick={handleClick}>
				{children}
			</ElementType>
		);
	}

	const iconElement = createListIcon(icon, { autoGenerateKey: false });
	const imageElement = createImage(image, { autoGenerateKey: false });

	// See description of `content` prop for explanation about why this is necessary.
	if (!isValidElement(content) && _.isPlainObject(content)) {
		return (
			<ElementType {...valueProp} role="listitem" {...rest} className={classes} onClick={handleClick}>
				{iconElement || imageElement}
				{createListContent(content, {
					autoGenerateKey: false,
					defaultProps: { header, description }
				})}
			</ElementType>
		);
	}

	const headerElement = createListHeader(header, { autoGenerateKey: false });
	const descriptionElement = createListDescription(description, { autoGenerateKey: false });

	if (iconElement || imageElement) {
		return (
			<ElementType {...valueProp} role="listitem" {...rest} className={classes} onClick={handleClick}>
				{iconElement || imageElement}
				{content || headerElement || descriptionElement ? (
					<ListContent>
						{headerElement}
						{descriptionElement}
						{content as ReactNode}
					</ListContent>
				) : null}
			</ElementType>
		);
	}

	return (
		<ElementType {...valueProp} role="listitem" {...rest} className={classes} onClick={handleClick}>
			{headerElement}
			{descriptionElement}
			{content}
		</ElementType>
	);
}

export const createListItem = createShorthandFactory(ListItem, content => ({
	content
}));
const handledProps = [
	'active',
	'as',
	'children',
	'className',
	'content',
	'description',
	'disabled',
	'header',
	'icon',
	'image',
	'onClick',
	'value'
];
