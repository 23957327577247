import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { asIssueId } from 'ts/commons/links/RequirementsTracingLinks';
import { EActivityPerspectiveView } from 'ts/perspectives/activity/EActivityPerspectiveView';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import type { TeamscaleIssueId } from 'typedefs/TeamscaleIssueId';

/**
 * Returns a URL to the history of a file.
 *
 * @param project The project.
 * @param uniformPath The uniform path for which to show the history.
 * @param commit The commit for which to show the history or null if not required.
 */
export function fileHistory(project: string, uniformPath: string, commit: UnresolvedCommitDescriptor | undefined) {
	return linkTo(EActivityPerspectiveView.RESOURCE_HISTORY, project, uniformPath, {
		commit
	});
}

/**
 * Returns a URL to the commit stream of a project.
 *
 * @param project The project.
 * @param params
 * @param params.user The user to filter the view on.
 */
export function commitStream(project: string, params?: { t?: UnresolvedCommitDescriptor | null; users?: string }) {
	return linkTo(EActivityPerspectiveView.COMMITS, project, null, params);
}

/** Returns a URL to the details of a commit */
export function commitDetails(
	project: string,
	commit: CommitDescriptor | UnresolvedCommitDescriptor,
	params?: {
		/** The color of the findingchurntable to scroll to. */
		churn?: string;
	}
) {
	return linkTo(EActivityPerspectiveView.COMMIT_DETAILS, project, {
		t: UnresolvedCommitDescriptor.wrap(commit),
		churn: params?.churn
	});
}

/** The parameters that can be passed to the Links.methodHistory function. */
export type MethodHistoryViewParameters = {
	startOffset: number;
	endOffset: number;
	partitions?: string[];
	crossAnnotationProjects?: string[];
	commit?: UnresolvedCommitDescriptor | null;
	baseline?: string | null;
	lastChangedTimestamp?: number;
	isTested?: boolean;
	isViewWithoutBaselineOptionLink?: boolean;
};

/**
 * Returns a URL to the method history view of a method specified by uniformPath, region and commit.
 *
 * @param project The project which contains the commit.
 * @param uniformPath The uniform path for which to show the code.
 * @param params
 * @param params.commit The commit (must have timestamp and branchName).
 * @param params.startOffset Start of the offset-based region the method lies in.
 * @param params.endOffset End of the offset-based region the method lies in.
 * @param params.partitions
 * @param params.crossAnnotationProjects
 * @param params.baseline The baseline timestamp as URL Token in milliseconds.
 * @param params.lastChangedTimestamp Commit timestamp of the last change to a method (Used in the TGA treemap with
 *   issue context).
 * @param params.isTested Whether the method was tested last (Used in the TGA treemap with issue context).
 * @param params.isViewWithoutBaselineOptionLink Whether the link was called from the code perspective.
 */
export function methodHistory(
	project: string,
	uniformPath: string,
	{ commit, ...params }: MethodHistoryViewParameters
) {
	return linkTo(EActivityPerspectiveView.METHOD_HISTORY, project, uniformPath, {
		t: commit,
		...params,
		methodHistoryTab: 0
	});
}

/** Returns the URL to a merge request */
export function mergeRequestDetails(project: string, mergeRequestId: string) {
	return linkTo(EActivityPerspectiveView.MERGE_REQUESTS, project, mergeRequestId);
}

/** Returns a URL for an issue query. */
export function issueQuery(project: string, query: string, queryName?: string) {
	return linkTo(EActivityPerspectiveView.ISSUES, project, {
		q: query,
		queryname: queryName
	});
}

/** Returns a URL for an issue */
export function issue(
	project: string,
	id: TeamscaleIssueId | string,
	params?: { selectedTestGapBranch?: string | null }
) {
	return linkTo(EActivityPerspectiveView.ISSUE_DETAIL, project, {
		id: asIssueId(id),
		issueBranch: params?.selectedTestGapBranch
	});
}
