import { QueryClient } from '@tanstack/react-query';

/** The query client used */
export const QUERY_CLIENT = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			// Avoid refetches when switching between IDE and browser, but get fresh data i.e., after creating a task in another tab (TS-41549)
			refetchOnWindowFocus: import.meta.env.PROD,
			// We want to handle errors by default via the error boundary to avoid that error handling is ignored.
			throwOnError: true,
			// Site should also work when not connected to the Internet (i.e. Demo Setup)
			networkMode: 'offlineFirst'
		},
		mutations: {
			// Site should also work when not connected to the Internet (i.e. Demo Setup)
			networkMode: 'offlineFirst'
		}
	}
});
