import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { CodeLineSelection } from 'ts/commons/code/CodeLineSelection';
import type { CodeOffsetSelection } from 'ts/commons/code/CodeOffsetSelection';
import { issueQuery } from 'ts/commons/links/ActivityLinks';
import * as ArchitectureLinks from 'ts/commons/links/ArchitectureLinks';
import { linkTo } from 'ts/commons/links/LinkTo';
import { specItemQuery } from 'ts/commons/links/RequirementsTracingLinks';
import { PathUtils } from 'ts/commons/PathUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { EMetricsPerspectiveView } from 'ts/perspectives/metrics/EMetricsPerspectiveView';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import { EResourceType, type EResourceTypeEntry } from 'typedefs/EResourceType';
import { EType } from 'typedefs/EType';

const TYPE_TO_VIEW = {
	[EType.CODE.name]: EMetricsPerspectiveView.FILES,
	[EType.NON_CODE.name]: EMetricsPerspectiveView.NON_CODE,
	[EType.ARCHITECTURE.name]: EMetricsPerspectiveView.ARCHITECTURE_COMPONENTS,
	[EType.TEST_EXECUTION.name]: EMetricsPerspectiveView.TESTS,
	[EType.TEST_IMPLEMENTATION.name]: EMetricsPerspectiveView.TESTS,
	[EType.TEST_QUERY.name]: EMetricsPerspectiveView.TESTS,
	[EType.SPEC_ITEM.name]: EMetricsPerspectiveView.SPEC_ITEMS,
	[EType.SPEC_ITEM_QUERY.name]: EMetricsPerspectiveView.SPEC_ITEMS,
	[EType.ISSUE_QUERY.name]: EMetricsPerspectiveView.ISSUES
} as const;

/** @returns A URL to show a single file. */
export function showFile(
	project: string,
	uniformPath: string,
	options?: { commit?: UnresolvedCommitDescriptor | CommitDescriptor | null }
) {
	return linkTo(EMetricsPerspectiveView.FILES, project, uniformPath, {
		t: UnresolvedCommitDescriptor.wrap(options?.commit)
	});
}

/** Links to a folder in the metrics perspective. */
export function showFolderMetrics(
	project: string,
	uniformPath: string,
	options?: {
		commit?: UnresolvedCommitDescriptor | CommitDescriptor | null;
		visibleMetric?: string | null;
		highlightMetric?: boolean;
		thresholdProfile?: string;
		partitions?: string[] | null;
	}
) {
	const type = new UniformPath(uniformPath).type;
	let view: ViewDescriptor;
	if (uniformPath.includes('.architecture')) {
		view = EMetricsPerspectiveView.ARCHITECTURE_COMPONENTS;
	} else {
		view = TYPE_TO_VIEW[type.name] ?? EMetricsPerspectiveView.FILES;
	}
	return linkTo(view, project, uniformPath, {
		t: UnresolvedCommitDescriptor.wrap(options?.commit),
		highlightMetric: options?.highlightMetric,
		visibleMetric: options?.visibleMetric,
		thresholdProfile: options?.thresholdProfile,
		partitions: options?.partitions
	});
}

/** Returns a URL for a test query. */
export function testQuery(
	project: string,
	query: string,
	params: {
		queryName?: string;
		commit?: UnresolvedCommitDescriptor | null;
	}
) {
	return linkTo(EMetricsPerspectiveView.TESTS, project, {
		t: params.commit,
		action: 'filter',
		q: query,
		queryname: params.queryName
	});
}

/** Returns a URL to the test detail perspective. */
export function testExecutionOrImplementation(
	project: string,
	uniformPath: string,
	commit: UnresolvedCommitDescriptor | CommitDescriptor | null | undefined,
	options?: { partition?: string | null }
) {
	return linkTo(EMetricsPerspectiveView.TESTS, project, uniformPath, {
		t: UnresolvedCommitDescriptor.wrap(commit),
		partition: options?.partition
	});
}

/**
 * Returns a url for navigating to a perspective showing metrics, which depends on the clicked path (e.g. code or
 * architecture).
 */
export function metrics(
	project: string,
	uniformPath: string,
	{
		commit,
		resourceType,
		visibleMetric,
		highlightMetric,
		thresholdProfile,
		partitions
	}: {
		/** The commit for which to show the metrics or null if the most recent version should be shown */
		commit?: UnresolvedCommitDescriptor | CommitDescriptor | null;
		/** The active threshold profile */
		thresholdProfile?: string;
		resourceType?: EResourceTypeEntry | null;
		/** A metric (by name) that should explicitly be visible in the target view */
		visibleMetric?: string | null;
		highlightMetric?: boolean;
		partitions?: string[] | null;
	} = {}
): string {
	const type = new UniformPath(uniformPath).type;

	if (type === EType.TEST_QUERY) {
		return linkTo(EMetricsPerspectiveView.TESTS, project, {
			action: 'filter',
			queryname: StringUtils.stripPrefix(uniformPath, EType.TEST_QUERY.prefix + '/'),
			partitions
		});
	}

	if (resourceType === EResourceType.FILE.name) {
		if (type === EType.ISSUE_QUERY) {
			return issueQuery(project, '', uniformPath);
		}

		if (type === EType.SPEC_ITEM_QUERY) {
			return specItemQuery(project, {
				queryName: uniformPath,
				specItemTab: 0
			});
		}

		if (type === EType.TEST_IMPLEMENTATION || type === EType.TEST_EXECUTION) {
			return testExecutionOrImplementation(project, uniformPath, commit);
		}

		return showFile(project, uniformPath, { commit });
	}
	return showFolderMetrics(project, uniformPath, {
		commit,
		visibleMetric,
		highlightMetric,
		partitions,
		thresholdProfile
	});
}

/** Returns a URL to the code perspective. This works for both containers and files. */
export function code(
	project: string,
	uniformPath: string,
	params?: {
		commit?: UnresolvedCommitDescriptor | CommitDescriptor | null;
		findingFilter?: string[];
		selection?: CodeLineSelection | CodeOffsetSelection;
		identifier?: string;
		highlight?: string;
		thresholdProfile?: string;
		visibleMetric?: string;
		highlightMetric?: boolean;
		showCoverage?: boolean;
	}
) {
	const type = new UniformPath(uniformPath).type;

	if (type === EType.TEST_IMPLEMENTATION || type === EType.TEST_EXECUTION) {
		return testExecutionOrImplementation(project, uniformPath, params?.commit);
	}
	if (type === EType.ARCHITECTURE) {
		return metrics(project, uniformPath, params);
	}

	// Build selection parameter
	return linkTo(TYPE_TO_VIEW[type.name] ?? EMetricsPerspectiveView.FILES, project, uniformPath, {
		t: UnresolvedCommitDescriptor.wrap(params?.commit),
		selection: params?.selection?.getUrlRepresentation(),
		filter: params?.findingFilter,
		identifier: params?.identifier,
		highlight: params?.highlight ?? '',
		profile: params?.thresholdProfile ?? '',
		visibleMetric: params?.visibleMetric,
		highlightMetric: params?.highlightMetric,
		showCoverage: params?.showCoverage
	});
}

/** Returns a URL to the corresponding code perspective or the architecture editor (if we have an .architecture file) */
export function file(
	project: string,
	uniformPath: string,
	params?: {
		commit?: UnresolvedCommitDescriptor | CommitDescriptor | null;
		visibleMetric?: string;
	}
) {
	if (uniformPath.endsWith(PathUtils.ARCHITECTURE_FILE_EXTENSION)) {
		return ArchitectureLinks.architectureEditor(project, uniformPath, {
			commit: params?.commit
		});
	}

	return code(project, uniformPath, {
		commit: params?.commit,
		visibleMetric: params?.visibleMetric
	});
}
