/** Generated from ESortOrder.java. Do not modify this file! */


export type ESortOrderEntry = 'ASCENDING' | 'DESCENDING';

export class ESortOrder {
	static readonly ASCENDING = new ESortOrder(0, 'ASCENDING');
	static readonly DESCENDING = new ESortOrder(1, 'DESCENDING');
	static readonly values = [
		ESortOrder.ASCENDING,
		ESortOrder.DESCENDING
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: ESortOrderEntry,

	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: ESortOrderEntry): ESortOrder;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: ESortOrderEntry | undefined): ESortOrder | undefined;
	public static valueOf(entry: ESortOrderEntry | undefined): ESortOrder | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return ESortOrder[entry];
	}
}

