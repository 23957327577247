import type { ReactNode } from 'react';
import { type JSX, useEffect } from 'react';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { AccountMenu } from 'ts/base/perspective/topbar/AccountMenu';
import { GlobalSearchBar } from 'ts/base/perspective/topbar/GlobalSearchBar';
import { HelpMenu } from 'ts/base/perspective/topbar/HelpMenu';
import { SupportChat } from 'ts/base/perspective/topbar/SupportChat';
import { GlobalWarnings } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';
import { GlobalProjectSelector } from 'ts/base/scaffolding/GlobalProjectSelector';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { useTeamscaleViewContext } from 'ts/base/TeamscaleViewContext';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { usePerspectiveProgressLoading } from 'ts/commons/PerspectiveProgress';
import { GlobalBranchSelectorWrapper, TimeTravelButtonWrapper, TimeTravelState } from 'ts/commons/TimeTravelState';
import { Menu, MenuItem } from 'ts/components/Menu';
import { Progress } from 'ts/components/Progress';

/**
 * The perspective settings bar (AKA top bar) for all perspectives. Provides links to the User perspective (via the 'My
 * account' dropdown), a logout button as well as search and help functionality. Optionally, project, branch selection
 * and time travel can be enabled.
 */
export function PerspectiveSettingsBar(): JSX.Element {
	const { viewDescriptor, projectIds, defaultBranchName } = useTeamscaleViewContext();
	const hash = useNavigationHash();
	useEffect(() => {
		// Reset the time travel commit to what is represented in the URL
		TimeTravelState.INSTANCE.reset(hash.getCommit());
	}, [hash]);

	const projectSelector = useProjectSelector(viewDescriptor);
	const AdditionComponent = viewDescriptor.additionalPerspectiveSettingsComponent ?? null;
	let globalBranchSelector: JSX.Element | null = null;
	let timeTravelElement: JSX.Element | null = null;

	if (viewDescriptor.timeTravel && projectIds.length > 0) {
		globalBranchSelector = (
			<GlobalBranchSelectorWrapper projectIds={projectIds} defaultBranch={defaultBranchName} />
		);
		if (!viewDescriptor.timeTravel.hideTimeTravelButton) {
			timeTravelElement = (
				<TimeTravelButtonWrapper
					projectIds={projectIds}
					timeTravelOptions={viewDescriptor.timeTravel}
					defaultBranch={defaultBranchName}
				/>
			);
		}
	}

	// 'segment' is needed for the progress bar to work
	return (
		<PerspectiveSettingsBarSkeleton timeTravelElement={timeTravelElement}>
			<SuspendingErrorBoundary errorFallback={() => null} suspenseFallback={null}>
				<div className="menu" data-hash={hash.toString()}>
					<MenuItem fitted id="selectors">
						{projectSelector}
						{globalBranchSelector}
						{AdditionComponent ? (
							<SuspendingErrorBoundary errorFallback={() => null} suspenseFallback={null}>
								<div className="fitted item side-fitting">
									<AdditionComponent key={hash.toString()} />
								</div>
							</SuspendingErrorBoundary>
						) : null}
					</MenuItem>
				</div>
			</SuspendingErrorBoundary>
		</PerspectiveSettingsBarSkeleton>
	);
}

/** Props for {@link PerspectiveSettingsBarSkeleton}. */
type PerspectiveSettingsBarSkeletonProps = { children?: ReactNode; timeTravelElement?: JSX.Element | null };

/** The skeleton for the perspective settings bar. */
export function PerspectiveSettingsBarSkeleton({ children, timeTravelElement }: PerspectiveSettingsBarSkeletonProps) {
	const isLoading = usePerspectiveProgressLoading();
	return (
		<Menu secondary className="perspective-settings-bar segment">
			{isLoading ? <Progress active={isLoading} attached="top" id="perspective-progress" /> : null}
			<SuspendingErrorBoundary errorFallback={() => null} suspenseFallback={null}>
				<GlobalWarnings />
			</SuspendingErrorBoundary>
			{children}
			<RightPerspectiveBarSection timeTravelElement={timeTravelElement} />
		</Menu>
	);
}

/** Renders the search and user section of the navigation bar. */
function RightPerspectiveBarSection({ timeTravelElement }: { timeTravelElement?: JSX.Element | null }): JSX.Element {
	const projectInfos = useProjectInfos();
	return (
		<>
			<div className="ml-auto" />
			{timeTravelElement}
			{projectInfos.getAllProjects().length === 0 ? null : <GlobalSearchBar />}
			<SupportChat />
			<HelpMenu />
			<span className="w-1" />
			<AccountMenu />
		</>
	);
}

/**
 * Returns the global project selector that should be shown in the perspective settings bar or null if no project
 * selector should be shown.
 */
function useProjectSelector(viewDescriptor: ViewDescriptor): JSX.Element | null {
	const showProjectSelector =
		useProjectInfos().projectsInfo.length > 0 &&
		viewDescriptor.requiresProject &&
		!viewDescriptor.hideProjectSelector;
	if (!showProjectSelector) {
		return null;
	}
	return (
		<GlobalProjectSelector
			projectIsFixed={Boolean(viewDescriptor.projectIsFixed)}
			showAllProjects={Boolean(viewDescriptor.showAllProjects)}
		/>
	);
}
