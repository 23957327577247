/** Generated from ELanguage.java. Do not modify this file! */


export type ELanguageEntry = 'JAVA' | 'CPP' | 'C' | 'OPEN_CL' | 'RUST' | 'VB' | 'COBOL' | 'CS' | 'ABAP' | 'ABAP_DDIC' | 'ADA' | 'TEXT' | 'XML' | 'SQLSCRIPT' | 'HANA_VIEW' | 'PLSQL' | 'PYTHON' | 'TSQL' | 'MATLAB' | 'PHP' | 'JAVASCRIPT' | 'LINE' | 'DELPHI' | 'IEC61131' | 'FORTRAN' | 'XTEND' | 'SWIFT' | 'OCAML' | 'OSCRIPT' | 'GROOVY' | 'NL_REQUIREMENTS' | 'NL_TESTS' | 'NL_ISSUES' | 'SIMULINK' | 'GOSU' | 'KOTLIN' | 'OBJECTIVE_C' | 'OBJECTIVE_CPP' | 'JAVADOC' | 'ABAPDOC' | 'GO' | 'ABAP_CDS' | 'POWERSHELL' | 'ESQL' | 'KUBERNETES' | 'DART';

export class ELanguage {
	static readonly JAVA = new ELanguage(0, 'JAVA', "Java", true, ["java"]);
	static readonly CPP = new ELanguage(1, 'CPP', "C++", true, ["cpp","hpp","cxx","hxx","cc","hh","h","inc","inl"]);
	static readonly C = new ELanguage(2, 'C', "C", true, ["c","h","inc"]);
	static readonly OPEN_CL = new ELanguage(3, 'OPEN_CL', "OpenCL C/C++", true, ["cl"]);
	static readonly RUST = new ELanguage(4, 'RUST', "Rust", true, ["rs"]);
	static readonly VB = new ELanguage(5, 'VB', "Visual Basic", false, ["vb","frm","cls","bas"]);
	static readonly COBOL = new ELanguage(6, 'COBOL', "COBOL", false, ["cbl","cob","cobol","cpy","eco"]);
	static readonly CS = new ELanguage(7, 'CS', "C#", true, ["cs"]);
	static readonly ABAP = new ELanguage(8, 'ABAP', "ABAP", false, ["abap"]);
	static readonly ABAP_DDIC = new ELanguage(9, 'ABAP_DDIC', "ABAP Dictionary", false, ["abap_ddic"]);
	static readonly ADA = new ELanguage(10, 'ADA', "Ada", false, ["ada","ads","adb"]);
	static readonly TEXT = new ELanguage(11, 'TEXT', "Plain Text", false, ["txt"]);
	static readonly XML = new ELanguage(12, 'XML', "XML", true, ["xml","xsl","xslt","architecture","cqb","csproj","config","prj"]);
	static readonly SQLSCRIPT = new ELanguage(13, 'SQLSCRIPT', "HANA SQLScript", false, ["sql","hdbprocedure","hdbfunction","hdbscalarfunction","hdbtablefunction"]);
	static readonly HANA_VIEW = new ELanguage(14, 'HANA_VIEW', "HANA View", true, ["analyticview","attributeview","calculationview"]);
	static readonly PLSQL = new ELanguage(15, 'PLSQL', "PL/SQL", false, ["sql","pks","pkb","trg","fnc","typ","tyb","prc","plsql"]);
	static readonly PYTHON = new ELanguage(16, 'PYTHON', "Python", true, ["py"]);
	static readonly TSQL = new ELanguage(17, 'TSQL', "Transact-SQL", false, ["tsql","sql"]);
	static readonly MATLAB = new ELanguage(18, 'MATLAB', "Matlab", true, ["m"]);
	static readonly PHP = new ELanguage(19, 'PHP', "PHP", true, ["php","php3","php4","php5"]);
	static readonly JAVASCRIPT = new ELanguage(20, 'JAVASCRIPT', "JavaScript/TypeScript", true, ["js","sj","jsx","ts","tsx"]);
	static readonly LINE = new ELanguage(21, 'LINE', "Line-based Text", false, ["yaml","yml","json"]);
	static readonly DELPHI = new ELanguage(22, 'DELPHI', "Delphi", false, ["pas","dpr"]);
	static readonly IEC61131 = new ELanguage(23, 'IEC61131', "IEC 61131-3 ST", true, ["pu","sv","st","scl","pou","dt","var","tu"]);
	static readonly FORTRAN = new ELanguage(24, 'FORTRAN', "Fortran", false, ["f","for","f77","f90","f95","f03"]);
	static readonly XTEND = new ELanguage(25, 'XTEND', "Xtend", true, ["xtend"]);
	static readonly SWIFT = new ELanguage(26, 'SWIFT', "Swift", true, ["swift"]);
	static readonly OCAML = new ELanguage(27, 'OCAML', "OCaml", true, ["ml","mli"]);
	static readonly OSCRIPT = new ELanguage(28, 'OSCRIPT', "OScript", true, ["osx","lxe","os"]);
	static readonly GROOVY = new ELanguage(29, 'GROOVY', "Groovy", true, ["groovy"]);
	static readonly NL_REQUIREMENTS = new ELanguage(30, 'NL_REQUIREMENTS', "Natural Language Requirements", false, []);
	static readonly NL_TESTS = new ELanguage(31, 'NL_TESTS', "Natural Language Tests", false, ["nltests"]);
	static readonly NL_ISSUES = new ELanguage(32, 'NL_ISSUES', "Natural Language Issues", false, []);
	static readonly SIMULINK = new ELanguage(33, 'SIMULINK', "Simulink and Stateflow", false, ["mdl","slx","sldd"]);
	static readonly GOSU = new ELanguage(34, 'GOSU', "Gosu", true, ["gsp","gs","gsx","gr","grs"]);
	static readonly KOTLIN = new ELanguage(35, 'KOTLIN', "Kotlin", true, ["kt","kts","ktm"]);
	static readonly OBJECTIVE_C = new ELanguage(36, 'OBJECTIVE_C', "Objective-C", true, ["m","h","c"]);
	static readonly OBJECTIVE_CPP = new ELanguage(37, 'OBJECTIVE_CPP', "Objective-C++", true, ["mm","h","inc","inl","cpp","hpp"]);
	static readonly JAVADOC = new ELanguage(38, 'JAVADOC', "JavaDoc", true, []);
	static readonly ABAPDOC = new ELanguage(39, 'ABAPDOC', "Abap Doc", true, []);
	static readonly GO = new ELanguage(40, 'GO', "Go", true, ["go"]);
	static readonly ABAP_CDS = new ELanguage(41, 'ABAP_CDS', "ABAP CDS", false, ["abap_cds","abap_ddlx","asddls","asddlxs","acds"]);
	static readonly POWERSHELL = new ELanguage(42, 'POWERSHELL', "Powershell", false, ["ps1","psd1","psm1"]);
	static readonly ESQL = new ELanguage(43, 'ESQL', "Extended SQL (ESQL)", false, ["esql"]);
	static readonly KUBERNETES = new ELanguage(44, 'KUBERNETES', "Kubernetes", false, ["yaml","json","yml"]);
	static readonly DART = new ELanguage(45, 'DART', "Dart", true, ["dart"]);
	static readonly values = [
		ELanguage.JAVA,
		ELanguage.CPP,
		ELanguage.C,
		ELanguage.OPEN_CL,
		ELanguage.RUST,
		ELanguage.VB,
		ELanguage.COBOL,
		ELanguage.CS,
		ELanguage.ABAP,
		ELanguage.ABAP_DDIC,
		ELanguage.ADA,
		ELanguage.TEXT,
		ELanguage.XML,
		ELanguage.SQLSCRIPT,
		ELanguage.HANA_VIEW,
		ELanguage.PLSQL,
		ELanguage.PYTHON,
		ELanguage.TSQL,
		ELanguage.MATLAB,
		ELanguage.PHP,
		ELanguage.JAVASCRIPT,
		ELanguage.LINE,
		ELanguage.DELPHI,
		ELanguage.IEC61131,
		ELanguage.FORTRAN,
		ELanguage.XTEND,
		ELanguage.SWIFT,
		ELanguage.OCAML,
		ELanguage.OSCRIPT,
		ELanguage.GROOVY,
		ELanguage.NL_REQUIREMENTS,
		ELanguage.NL_TESTS,
		ELanguage.NL_ISSUES,
		ELanguage.SIMULINK,
		ELanguage.GOSU,
		ELanguage.KOTLIN,
		ELanguage.OBJECTIVE_C,
		ELanguage.OBJECTIVE_CPP,
		ELanguage.JAVADOC,
		ELanguage.ABAPDOC,
		ELanguage.GO,
		ELanguage.ABAP_CDS,
		ELanguage.POWERSHELL,
		ELanguage.ESQL,
		ELanguage.KUBERNETES,
		ELanguage.DART
	];



	static readonly LANGUAGES_WITHOUT_METHODS = [
		ELanguage.ABAP_DDIC,
		ELanguage.HANA_VIEW,
		ELanguage.LINE,
		ELanguage.TEXT,
		ELanguage.XML
	];

	static readonly LANGUAGES_WITH_PREPROCESSOR_EXPANSIONS = [
		ELanguage.C,
		ELanguage.CPP,
		ELanguage.OBJECTIVE_C,
		ELanguage.OBJECTIVE_CPP,
		ELanguage.SWIFT
	];

	static readonly HIDDEN_LANGUAGES = [
		ELanguage.KUBERNETES,
		ELanguage.RUST
	];

	private constructor (
		public readonly ordinal: number,
		public readonly name: ELanguageEntry,
		public readonly readableName: string,
		public readonly caseSensitive: boolean,
		public readonly extensions: Array<string>
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: ELanguageEntry): ELanguage;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: ELanguageEntry | undefined): ELanguage | undefined;
	public static valueOf(entry: ELanguageEntry | undefined): ELanguage | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return ELanguage[entry];
	}
}

