/** Generated from EBasicPermission.java. Do not modify this file! */


export type EBasicPermissionEntry = 'VIEW' | 'EDIT' | 'DELETE' | 'EDIT_ROLES';

export class EBasicPermission {
	static readonly VIEW = new EBasicPermission(0, 'VIEW', "View");
	static readonly EDIT = new EBasicPermission(1, 'EDIT', "Edit");
	static readonly DELETE = new EBasicPermission(2, 'DELETE', "Delete");
	static readonly EDIT_ROLES = new EBasicPermission(3, 'EDIT_ROLES', "Assign Roles");
	static readonly values = [
		EBasicPermission.VIEW,
		EBasicPermission.EDIT,
		EBasicPermission.DELETE,
		EBasicPermission.EDIT_ROLES
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EBasicPermissionEntry,
		public readonly readableName: string
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: EBasicPermissionEntry): EBasicPermission;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: EBasicPermissionEntry | undefined): EBasicPermission | undefined;
	public static valueOf(entry: EBasicPermissionEntry | undefined): EBasicPermission | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EBasicPermission[entry];
	}
}

