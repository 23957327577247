import { CodeSelection } from './CodeSelection';

/** Describes a selection of code based on line numbers. */
export class CodeLineSelection extends CodeSelection {
	/**
	 * @param firstLine The first line of the selection
	 * @param lastLine The last line of the selection
	 */
	public constructor(
		public readonly firstLine: number,
		public readonly lastLine: number = firstLine
	) {
		super();
	}

	public override getUrlRepresentation(): string {
		return this.firstLine + CodeSelection.URL_PARAMETER_SEPARATOR + this.lastLine;
	}

	public override resolve(): CodeLineSelection {
		return this;
	}
}
