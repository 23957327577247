/** Generated from EFindingEnablement.java. Do not modify this file! */


export type EFindingEnablementEntry = 'OFF' | 'YELLOW' | 'RED' | 'AUTO' | 'UNDECIDED';

export class EFindingEnablement {
	static readonly OFF = new EFindingEnablement(0, 'OFF', false);
	static readonly YELLOW = new EFindingEnablement(1, 'YELLOW', true);
	static readonly RED = new EFindingEnablement(2, 'RED', true);
	static readonly AUTO = new EFindingEnablement(3, 'AUTO', true);
	static readonly UNDECIDED = new EFindingEnablement(4, 'UNDECIDED', false);
	static readonly values = [
		EFindingEnablement.OFF,
		EFindingEnablement.YELLOW,
		EFindingEnablement.RED,
		EFindingEnablement.AUTO,
		EFindingEnablement.UNDECIDED
	];



	private constructor (
		public readonly ordinal: number,
		public readonly name: EFindingEnablementEntry,
		public readonly enabled: boolean
	){
	}

	toString() {
		return this.name;
	}

	/** Returns the enum value of the provided enum entry. */
	public static valueOf(entry: EFindingEnablementEntry): EFindingEnablement;
	/** Returns the enum value of the provided enum entry. Returns undefined if entry was undefined. */
	public static valueOf(entry: EFindingEnablementEntry | undefined): EFindingEnablement | undefined;
	public static valueOf(entry: EFindingEnablementEntry | undefined): EFindingEnablement | undefined {
		if (entry === undefined) {
			return undefined;
		}
		return EFindingEnablement[entry];
	}
}

