import clsx from 'clsx';
import * as _ from 'es-toolkit/compat';
import {
	cloneElement,
	type ComponentPropsWithRef,
	createElement,
	type ElementType,
	type ForwardedRef,
	isValidElement,
	type ReactNode,
	type JSX
} from 'react';
import type {
	SemanticShorthandCollection,
	SemanticShorthandContent,
	SemanticShorthandItem
} from 'ts/components/Generic';

// ============================================================
// Factories
// ============================================================

type ShorthandOptions<P> = {
	/** Default props object. Default is `{}` */
	defaultProps?: P;
	/** Props object or function (called with regular props). Default is `{}`. */
	overrideProps?: P | ((p: P) => P);
	/** Whether or not automatic key generation is allowed. Default is `true` */
	autoGenerateKey?: boolean;
	ref?: ForwardedRef<HTMLDivElement>;
};

/**
 * A more robust createElement. It can create elements from primitive values.
 *
 * @param Component A ReactClass or string
 * @param mapValueToProps A function that maps a primitive value to the Component props
 * @param val The value to create a ReactElement from
 */
export function createShorthand<
	E extends ElementType = ElementType,
	V extends string | SemanticShorthandCollection<Record<string, unknown>> = string
>(
	Component: E,
	mapValueToProps: ((v: V) => ComponentPropsWithRef<E>) | undefined | null,
	val:
		| SemanticShorthandItem<ComponentPropsWithRef<E>>
		| SemanticShorthandContent
		| SemanticShorthandCollection<Record<string, unknown>>
		| boolean,
	options: ShorthandOptions<ComponentPropsWithRef<E>> = {}
): JSX.Element | null | undefined {
	// short circuit noop values
	if (val == null || _.isBoolean(val)) {
		return null;
	}

	const valIsString = _.isString(val);
	const valIsNumber = _.isNumber(val);
	const valIsReactElement = isValidElement(val);
	const valIsPropsObject = _.isPlainObject(val);
	const valIsPrimitiveValue = valIsString || valIsNumber || Array.isArray(val);

	// unhandled type return null
	if (!valIsReactElement && !valIsPropsObject && !valIsPrimitiveValue) {
		if (import.meta.env.DEV) {
			console.error(
				[
					'Shorthand value must be a string|number|array|object|ReactElement.',
					' Use null|undefined|boolean for none',
					` Received ${typeof val}.`
				].join('')
			);
		}
		return null;
	}

	// ----------------------------------------
	// Build up props
	// ----------------------------------------
	const { defaultProps = {} } = options as unknown as ShorthandOptions<{
		className?: string;
		style?: object;
		children?: ReactNode;
	}>;

	// User's props
	const usersProps = ((valIsReactElement && val.props) ||
		(valIsPropsObject && val) ||
		(valIsPrimitiveValue && mapValueToProps?.(val as V))) as ComponentPropsWithRef<E> & {
		className?: string;
		style?: object;
		children?: ReactNode;
	};

	// Override props
	const { overrideProps = {} } = options as unknown as ShorthandOptions<{
		className?: string;
		style?: object;
		children?: ReactNode;
	}>;
	const overriddenProps = (
		_.isFunction(overrideProps) ? overrideProps({ ...defaultProps, ...usersProps }) : overrideProps
	) as {
		className?: string;
		style?: object;
		children?: ReactNode;
	};

	// Merge props
	const props = { ...defaultProps, ...usersProps, ...overriddenProps };

	// Merge className
	if (defaultProps.className || overriddenProps.className || usersProps.className) {
		const mergedClassesNames = clsx(defaultProps.className, overriddenProps.className, usersProps.className);
		props.className = _.uniq(mergedClassesNames.split(' ')).join(' ');
	}

	// Merge style
	if (defaultProps.style || overriddenProps.style || usersProps.style) {
		props.style = { ...defaultProps.style, ...usersProps.style, ...overriddenProps.style };
	}

	// ----------------------------------------
	// Get key
	// ----------------------------------------

	// Use key, childKey, or generate key
	if (_.isNil(props.key)) {
		const { childKey } = props;
		const { autoGenerateKey = true } = options;

		if (!_.isNil(childKey)) {
			// apply and consume the childKey
			props.key = typeof childKey === 'function' ? childKey(props) : childKey;
			delete props.childKey;
		} else if (autoGenerateKey && (valIsString || valIsNumber)) {
			// use string/number shorthand values as the key
			props.key = val;
		}
	}

	// ----------------------------------------
	// Create Element
	// ----------------------------------------

	// Clone ReactElements
	if (valIsReactElement) {
		return cloneElement(val, props);
	}

	if (typeof props.children === 'function') {
		return props.children(Component, { ...props, children: undefined });
	}

	// Create ReactElements from built up props
	if (valIsPrimitiveValue || valIsPropsObject) {
		return createElement(Component, props);
	}
	return undefined;
}

// ============================================================
// Factory Creators
// ============================================================

/**
 * Creates a `createShorthand` function waiting for a value and options.
 *
 * @param Component A ReactClass or string
 * @param mapValueToProps A function that maps a primitive value to the Component props
 * @returns A shorthand factory function waiting for `val` and `defaultProps`.
 */
export function createShorthandFactory<
	E extends ElementType = ElementType,
	V extends string | SemanticShorthandCollection<Record<string, unknown>> = string
>(
	Component: E,
	mapValueToProps?: ((v: V) => ComponentPropsWithRef<E>) | null
): (
	val: SemanticShorthandItem<ComponentPropsWithRef<E>> | SemanticShorthandContent | V | boolean,
	options?: ShorthandOptions<ComponentPropsWithRef<E>>
) => JSX.Element | null | undefined {
	return (
		val: SemanticShorthandItem<ComponentPropsWithRef<E>> | SemanticShorthandContent | V | boolean,
		options?: ShorthandOptions<ComponentPropsWithRef<E>>
	) => createShorthand(Component, mapValueToProps, val, options);
}

// ============================================================
// HTML Factories
// ============================================================
export const createHTMLDivision = /* #__PURE__ */ createShorthandFactory('div', val => ({
	children: val
}));
export const createHTMLImage = /* #__PURE__ */ createShorthandFactory('img', val => ({
	src: val
}));
export const createHTMLInput = /* #__PURE__ */ createShorthandFactory('input', val => ({
	type: val
}));
export const createHTMLLabel = /* #__PURE__ */ createShorthandFactory('label', val => ({
	children: val
}));
export const createHTMLParagraph = /* #__PURE__ */ createShorthandFactory('p', val => ({
	children: val
}));
