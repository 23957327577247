import clsx from 'clsx';
import { type ElementType, type ReactNode, type RefObject } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps, keyOnly } from '../lib';

/** Props for {@link PlaceholderHeader}. */
export type PlaceholderHeaderProps = {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A placeholder can contain an image. */
	image?: boolean;
};

/** A placeholder can contain a header. */
export function PlaceholderHeader(props: PlaceholderHeaderProps) {
	const { children, className, content, image } = props;
	const classes = clsx(keyOnly(image, 'image'), 'header', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}
const handledProps = ['as', 'children', 'className', 'content', 'image'];
