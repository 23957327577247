import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type RefObject } from 'react';
import { getComponentType, getUnhandledProps, keyOnly } from '../lib';

/** Props for {@link PlaceholderImage}. */
export type PlaceholderImageProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Additional classes. */
	className?: string;

	/** An image can modify size correctly with responsive styles. */
	square?: boolean;

	/** An image can modify size correctly with responsive styles. */
	rectangular?: boolean;
};

/** A placeholder can contain an image. */
export function PlaceholderImage(props: PlaceholderImageProps) {
	const { className, square, rectangular } = props;
	const classes = clsx(keyOnly(square, 'square'), keyOnly(rectangular, 'rectangular'), 'image', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return <ElementType {...rest} className={classes} />;
}
const handledProps = ['as', 'className', 'rectangular', 'square'];
