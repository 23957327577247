import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ElementType, type ReactNode, type RefObject } from 'react';
import type { SemanticShorthandContent } from '../Generic';
import { childrenUtils, getComponentType, getUnhandledProps, keyOnly } from '../lib';

/** Props for {@link Placeholder}. */
export type PlaceholderProps = ComponentPropsWithoutRef<'div'> & {
	/** An element type to render as (string or function). */
	as?: ElementType;

	/** The ref allows retrieving a reference to the underlying DOM node. */
	ref?: RefObject<HTMLDivElement>;

	/** Primary content. */
	children?: ReactNode;

	/** Additional classes. */
	className?: string;

	/** Shorthand for primary content. */
	content?: SemanticShorthandContent;

	/** A fluid placeholder takes up the width of its container. */
	fluid?: boolean;

	/** A placeholder can have their colors inverted. */
	inverted?: boolean;
};

/**
 * A placeholder is used to reserve space for content that soon will appear in a layout.
 *
 * ```tsx
 * import { Placeholder } from 'ts/components/Placeholder';
 * ```
 */
export function Placeholder(props: PlaceholderProps) {
	const { children, className, content, fluid, inverted } = props;
	const classes = clsx('ui', keyOnly(fluid, 'fluid'), keyOnly(inverted, 'inverted'), 'placeholder', className);
	const rest = getUnhandledProps(handledProps, props);
	const ElementType = getComponentType(props);

	return (
		<ElementType {...rest} className={classes}>
			{childrenUtils.isNil(children) ? content : children}
		</ElementType>
	);
}
const handledProps = ['as', 'children', 'className', 'content', 'fluid', 'inverted'];
